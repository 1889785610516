<template>
    <div v-if="shouldShowTopLevelContractExpirationWarning">
        <h6 class="">{{ $t("myCadac.contractOverview.subscription.expirationWarning.title") }}</h6>
        <p class="mb-2">{{ $t('myCadac.contractOverview.subscription.expirationWarning.text') }}</p>
    </div>
    <DataTable v-if="subscriptions.length"
               auto-layout
               v-model:expanded-rows="expandedRows"
               responsive-layout="stack"
               breakpoint="991px"
               :row-class="highlightRow"
               :value="subscriptions"
               class="subscription-table">
        <Column expander />
        <Column key="endDate" field="endDate" class="end-date-column" style="width: 25%"
                :header="$t('myCadac.contractOverview.table.colExpiresOn')" :sortable="false">
            <template #body="item">
                <div v-if="shouldShowContractExpirationWarning(item.data)"
                     class="text-danger d-flex flex-nowrap overflow-hidden">
                    <span class="text-nowrap mr-1">{{ formatDate(item.data.endDate) }}</span>
                    <PopoverIcon class="d-inline-flex" style="line-height: 1.75rem">
                        <i class="pi pi-exclamation-circle" />
                        <template #content>
                            <p>
                                {{
									$tc("myCadac.contractOverview.table.contractExpirationWarning", {
										count: item.data.remainingDays,
									})
                                }}
                            </p>
                        </template>
                    </PopoverIcon>
                </div>
                <span v-else>{{ formatDate(item.data.endDate) }}</span>
            </template>
        </Column>
        <Column key="products"
                field="products"
                style="width: 25%"
                :header="$t('myCadac.contractOverview.table.colProducts')"
                :sortable="false">
            <template #body="item">
                <AvatarGroup>
                    <Avatar v-for="(asset, index) in getSortedSubscriptionAsset(item.data)?.slice(
							0,
							maxContractAssetsToShow
						)"
                            :key="index"
                            :alt="asset.productIconAlt"
                            class="ml-0 bg-transparent"
                            :image="asset.productIconUrl"
                            shape="square"
                            size="small"
                            :title="asset.productName">
                    </Avatar>
                    <Avatar v-if="item.data.autodeskContractAssets?.length > maxContractAssetsToShow"
                            class="text-bold ml-0"
                            :label="`+${item.data.autodeskContractAssets?.length - maxContractAssetsToShow}`"
                            shape="square"
                            size="small">
                    </Avatar>
                </AvatarGroup>
            </template>
        </Column>
        <Column key="renewalTerm" field="duration" style="width: 15%"
                :header="$t('myCadac.contractOverview.table.colDuration')" :sortable="false">
            <template #body="assetDuration">
                <div class="spacing-column">
                    <p class="my-1 text-bold text-right text-lg-left">
                        {{ assetDuration.data.duration }}
                    </p>
                </div>
            </template>
        </Column>
        <Column key="automaticRenewal" field="automaticRenewal" style="width: 15%"
                :header="$t('myCadac.contractOverview.table.colAutomaticRenewal')" :sortable="false">
            <template #body="item">
                <div class="d-flex align-items-center">
                    <i :class="item.data.automaticRenewal
						? 'pi pi-check text-success mr-1'
						: 'pi pi-times text-danger mr-1'
						" />
                    <span style="line-height: 1.25rem">
                        {{ getAutomaticRenewalText(item.data.automaticRenewal) }}
                    </span>
                </div>
            </template>
        </Column>
        <Column key="actions" style="width: 20%">
            <template #body="item">
                <div class="d-flex justify-content-end flex-grow-1">
                    <Button v-if="shouldShowContractExpirationWarning(item.data)"
                            class="p-button-success p-button-sm mt-1"
                            :label="$t('myCadac.contractOverview.subscription.expirationWarning.buttonText')"
                            @click="openUrl(vm.contactPageUrl, '_blank');
                                    $event.target.disabled = true;" />
                </div>
            </template>
        </Column>
        <template #expansion="item">
            <div class="row-expansion-content">
                <DataTable v-if="item?.data?.autodeskContractAssets?.length"
                           :value="item?.data?.autodeskContractAssets">
                    <Column field="productName" class="name-column"
                            :header="$t('myCadac.contractOverview.table.colProductName')" :sortable="false">
                        <template #body="asset">
                            <div class="d-flex align-items-center product-name-wrapper">

                                <Avatar :alt="asset.data.productIconAlt" class="mr-1 bg-transparent flex-shrink-0 d-none d-lg-block"
                                        :image="asset.data.productIconUrl" shape="square" size="large" :title="asset.data.productName">
                                </Avatar>
                                <div class="d-flex flex-column align-items-end align-items-lg-start">
                                    <p v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
                                       class="mb-0 text-bold text-right text-lg-left">
                                        <a :href="asset.data.productCrmUrl" target="_blank" :title="$t('common.goToCrm')">
                                            {{ formatProductName(asset.data.productName, asset.data.productBrand) }}
                                        </a>
                                    </p>

                                    <p v-else class="mb-0 text-bold text-right text-lg-left">
                                        {{ formatProductName(asset.data.productName, asset.data.productBrand) }}
                                    </p>

                                    <span class="badge badge-primary multi-user-badge">
                                        {{ asset.data.multiSizeUnitString }}
                                    </span>
                                </div>

                            </div>
                        </template>
                    </Column>
                    <Column style="width: 15%" field="seats" :header="$t('myCadac.contractOverview.table.colProductSeats')" :sortable="false">
                        <template #body="asset">
                            <div>
                                <p class="my-1 text-bold text-right text-lg-left">
                                    {{ asset.data.seats }}
                                </p>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Message v-else :closable="false" severity="warn">
                    {{ $t("myCadac.contractOverview.message.noContractAssets") }}
                </Message>
            </div>
        </template>
    </DataTable>
    <Card v-if="!subscriptions.length" class="mx-auto mt-2">
        <template #content>
            <p class="lead">
                {{ $t("myCadac.contractOverview.card.noContracts") }}
            </p>
        </template>
    </Card>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Prop, Component } from "vue-facing-decorator";
import { Subscription } from "@/types/models/subscriptions/subscription";
import { IContractOverview } from "@/types/viewModels/myCadac/contractOverviewViewModel";
import { PropType } from "vue";
import { SubscriptionAsset } from '@/types/models/subscriptions/SubscriptionAsset';

@Component
export default class SubscriptionOverviewTable extends BaseComponent {
	@Prop({ type: Object as PropType<IContractOverview>, required: true, default: () => ({}) })
	vm!: IContractOverview;
	@Prop({ type: Array as PropType<Subscription[]>, required: true, default: () => [] })	
    subscriptions!: Subscription[];

    daysBeforeExpirationWarning = 90;
    maxContractAssetsToShow = 3;
    expandedRows: Subscription[] = [];

    created() {
        // open first subcsription row
        //this.expandedRows = [this.subscriptions?.[0]] || [];
    }

    highlightRow(contract: any): string {
        const isRowExpanded = this.expandedRows?.findIndex((x: any) => x.autodeskContractAssets?.[0]?.serialNumber === contract.autodeskContractAssets?.[0]?.serialNumber) > -1;
        return isRowExpanded ? "highlight" : "";
    }

    getSortedSubscriptionAsset(subscription: any): SubscriptionAsset[] {
        const otherAssets: SubscriptionAsset[] = [];

        const uniqueIconAssets = subscription.autodeskContractAssets.reduce((result: SubscriptionAsset[], value: SubscriptionAsset) => {
                if (result.findIndex((x) => x.productName === value.productName) === -1) {
                    result.push(value);
                } else {
                    otherAssets.push(value);
                }
                return result;
            },
            [] as SubscriptionAsset[]
        );

        return [...uniqueIconAssets, ...otherAssets];
    }

	getAutomaticRenewalText(automaticRenewal: boolean): string {
		return automaticRenewal ? this.$t("common.on").toString() : this.$t("common.off").toString();
	}

	isAlmostExpired(subscription: Subscription): boolean {
		return (
			(subscription.remainingDays <= this.daysBeforeExpirationWarning &&
				subscription.remainingDays > 0 &&
				!subscription.automaticRenewal) ||
			false
		);
	}

	shouldShowContractExpirationWarning(subscription: Subscription): boolean {
		return this.isAlmostExpired(subscription);
	}

	get shouldShowTopLevelContractExpirationWarning(): boolean {
		return this.subscriptions.some((subscription) => this.isAlmostExpired(subscription));
	}
}
</script>

<style lang="scss" scoped>
    .subscription-table {
        font-size: 80%;

        @media (min-width: 991px) {
            font-size: 100%;
        }

        .multi-user-badge {
            font-size: 80%;

            @media (min-width: 991px) {
                font-size: 100%;
            }
        }

        .name-column {
            padding-top: 1.2rem !important;

            @media (min-width: 991px) {
                padding-top: 0.5rem !important;
            }

            div {
                padding: .2rem;
            }
        }

        .end-date-column {
            padding-bottom: 1.2rem !important;

            @media (min-width: 991px) {
                padding-bottom: 0.5rem !important;
            }
        }
    }

    .p-column-title {
	    max-width: 50%;

	    @media (min-width: 991px) {
		    max-width: 100%;
	    }
    }

</style>
