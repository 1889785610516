import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "m-2 surface-a"
}
const _hoisted_2 = { class: "p-3" }
const _hoisted_3 = { class: "d-flex mt-2" }
const _hoisted_4 = { class: "d-flex flex-column mt-2" }
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-shrink-0 m-2 surface-a"
}
const _hoisted_6 = { class: "d-flex flex-column p-3 w-50" }
const _hoisted_7 = { class: "d-flex mt-2" }
const _hoisted_8 = { class: "d-flex flex-column mt-2" }
const _hoisted_9 = { class: "w-50 d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_ctx.isGridLayout)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Skeleton, {
              class: "mr-2",
              style: {"background-color":"#FEE4EB"},
              height: "4.5rem",
              shape: "square",
              width: "4rem"
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_Skeleton, {
                class: "mb-2",
                width: "5rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mb-2",
                width: "15rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mb-2",
                width: "10rem"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Skeleton, {
              class: "mb-1",
              height: ".75rem",
              width: "100%"
            }),
            _createVNode(_component_Skeleton, {
              height: ".75rem",
              width: "80%"
            })
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Skeleton, {
              class: "mr-2",
              style: {"background-color":"#FEE4EB"},
              height: "4.5rem",
              shape: "square",
              width: "4rem"
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_Skeleton, {
                class: "mb-2",
                width: "5rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mb-2",
                width: "15rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mb-2",
                width: "10rem"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Skeleton, {
              class: "mb-1",
              height: ".75rem",
              width: "100%"
            }),
            _createVNode(_component_Skeleton, {
              height: ".75rem",
              width: "80%"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Skeleton, {
            style: {"background-color":"#FEE4EB"},
            height: "100%",
            width: "100%"
          })
        ])
      ]))
}