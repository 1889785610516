import '@/assets/styles/styles.scss';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueRecaptcha from 'vue3-recaptcha-v2';
import VueTelInput from 'vue-tel-input';
import i18n, {
	getActiveLocale,
    loadMessagesFromTranslationController,
} from '@/types/utils/i18n';
import { IComponent } from '@/types/models/common/component.interface';
import { LazyLoadImageDirective } from '@/directives/lazyLoadImageDirective';
import { Log } from '@/types/helpers/logHelper';
import { blocks } from '@/views/blocks/_index';
import { components, primeComponents } from '@/components';
import { configureValidationMessages } from '@/types/utils/validators';
import { createApp } from 'vue';
import { createMemoryHistory, createRouter } from 'vue-router';
import { createPinia } from 'pinia';
import { googleTagManager } from '@/types/utils/gtm';
import { loadIcons } from '@/types/utils/fa-library';
import { registerScrollSpy } from 'vue3-scroll-spy';
import { vIntersectionObserver } from '@vueuse/components';
import { views } from '@/views';
import { vue3Debounce } from 'vue-debounce';

///////////////////////////////////////////////////////////////////////////
/* eslint-disable @typescript-eslint/no-explicit-any*/

const app = createApp({
	name: 'CadacStore',
});
app.use(i18n);
app.use(googleTagManager);
app.use(createPinia());
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(DialogService);
app.use(ToastService);
app.use(VueTelInput, {
	preferredCountries: ['nl', 'be', 'de', 'gb', 'ch', 'at', 'es', 'pt', 'it'],
	autoFormat: true,
	validCharactersOnly: true,
	mode: 'international',
});
app.use(VueGoogleMaps, { load: { key: process.env.VUE_APP_GOOGLE_API_KEY, libraries: 'places' } });
app.use(
	createRouter({
		history: createMemoryHistory(),
		routes: [],
	})
);
app.use(VueRecaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY });

app.directive('lazy', LazyLoadImageDirective);
app.directive('badge', BadgeDirective);
app.directive('debounce', vue3Debounce({ lock: true, listenTo: ['input'], fireOnEmpty: false }));
app.directive('vue-intersect', vIntersectionObserver);
registerScrollSpy(app);

let hasLoadedViews = false;

window.addEventListener('load', function () {
	if (!hasLoadedViews) {
		loadApplication();
	}
});

/**
 * This is the main function where the application components/views/translations will be loaded.
 * If views are loaded the application will be mounted
 */
async function loadApplication(this) {
	await loadOnlineTranslations();
	loadServiceWorker();
	loadComponents();
	loadBlocks();
	loadIcons();
	hasLoadedViews = loadViews();

	if (hasLoadedViews) {
		hideLoadingSpinnerAndShowBody();

		app.mount('#app');
	}
	dispatchEvent(new Event('VueAppLoaded'));
}

/**
 * This function is used to register custom Vue components globally.
 * Will register all components from the components/index.ts
 */
const loadComponents = () => {
	components.forEach((x: IComponent) => {
		app.component(x.element, x.component as any);
	});

	primeComponents.forEach((x: IComponent) => {
		app.component(x.element, x.component as any);
	});
};

/**
 * This function is used to register custom Vue components for blocks globally.
 * Will register all blocks from the blocks/_index.ts
 */
const loadBlocks = () => {
	blocks.forEach((x: IComponent) => {
		app.component(x.element, x.component as any);
	});
};

/**
 * This function is used to register the Vue components globally.
 * Will check for each view from views/index.ts if it is in the DOM before loading it.
 */
const loadViews = (): boolean => {
	let hasLoadedView = false;
	views.forEach((x: IComponent) => {
		if (!document.querySelector(x.element)) return;
		Log.info(`Load view ${x.element}`);
		app.component(x.element, x.component as any);
		hasLoadedView = true;
	});
	return hasLoadedView;
};

/**
 * This function is used to register our service worker that will pre-cache the '../Epi.Website/Static' folder
 * Additionally it dynamically caches URL's for images etc - see workbox-config.js for the config.
 */
const loadServiceWorker = () => {
	navigator.serviceWorker
		?.register('/Static/sw.js')
		.then(() => {
			Log.info('Service worker has been registered');
		})
		.catch((err) => {
			Log.warn('ServiceWorker registration failed:', err);
		});
};

/**
 * Loads translations from online sources (CDN/API) for the active locale
 */
const loadOnlineTranslations = async () => {
	try {
		const activeLocale = getActiveLocale();
		const messages = await loadMessagesFromTranslationController();
		Log.info(`Fetched online translations for locale: ${activeLocale}.`);
		i18n.global.mergeLocaleMessage(activeLocale, messages);
		configureValidationMessages(messages);
	} catch (err) {
		Log.warn(
			`Unable to load translations from Localazy.com - Falling back to local files for locale: ${getActiveLocale()}.`,
			err
		);
	}
};

/**
 * This function is used to hide the default loading spinner in the MasterLayout
 * and removes the display:none from the MVC server rendered body
 */
const hideLoadingSpinnerAndShowBody = () => {
	const mvcBody = document.querySelector('.main-body');
	const loadingSpinner = document.querySelector('.loading-container');
	mvcBody?.setAttribute('style', '');
	loadingSpinner?.parentNode?.removeChild(loadingSpinner);
};
