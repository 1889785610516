import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b834989"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "h-100 card-list-info" }
const _hoisted_3 = { class: "d-flex mb-1" }
const _hoisted_4 = { class: "align-self-center mr-2" }
const _hoisted_5 = ["alt", "title"]
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "mb-0 text-muted lead" }
const _hoisted_8 = { class: "mb-0 productname" }
const _hoisted_9 = { class: "mt-2 mt-lg-0 d-flex" }
const _hoisted_10 = {
  key: 0,
  class: "lead text-muted d-flex flex-wrap text-right justify-content-end"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "text-primary" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "text-primary ml-1" }
const _hoisted_15 = {
  key: 1,
  class: "lead text-muted d-flex flex-wrap text-right justify-content-end"
}
const _hoisted_16 = { class: "lead price-text text-muted" }
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("a", {
    class: "service-card-item",
    href: _ctx.item.productPageUrl,
    style: {"text-decoration":"none"}
  }, [
    _createVNode(_component_Card, {
      class: _normalizeClass({ 'service-grid-card': _ctx.isGridLayout, 'service-list-card': !_ctx.isGridLayout, compact: _ctx.compact, 'cross-sell': _ctx.isCrossSell }),
      style: _normalizeStyle({ backgroundColor: _ctx.item.backgroundColor }),
      title: _ctx.formatProductName(_ctx.item.name, _ctx.item.brand),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUrl(_ctx.item.productPageUrl)))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.item.iconUrl)
                ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: _ctx.item.iconAlt,
                    class: "overview-product-icon",
                    title: _ctx.item.name
                  }, null, 8, _hoisted_5)), [
                    [_directive_lazy, _ctx.item.iconUrl]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('enum.itemProductType.' + _ctx.item.productType)), 1),
              _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.formatProductName(_ctx.item.name, _ctx.item.brand)), 1),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.item.basePrice)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                      (_ctx.isServiceType)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                            _createElementVNode("b", _hoisted_12, _toDisplayString(_ctx.formatCurrency(_ctx.item.basePrice)), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_13, [
                            _createTextVNode(_toDisplayString(_ctx.$t("common.from")) + " ", 1),
                            _createElementVNode("b", _hoisted_14, _toDisplayString(_ctx.formatCurrency(_ctx.item.basePrice)), 1)
                          ]))
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.item.basePrice)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                      _createElementVNode("span", null, [
                        _createElementVNode("b", _hoisted_16, _toDisplayString(_ctx.$t("product.availableOnRequest")), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isCrossSell)
                ? (_openBlock(), _createBlock(_component_Tag, {
                    key: 0,
                    class: "mb-auto",
                    value: _ctx.$t('enum.itemProductType.' + _ctx.item.productType)
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ])
          ]),
          (!_ctx.isCrossSell)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_Divider),
                (_ctx.showIntro)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: "intro-text text-preline",
                      innerHTML: _ctx.item.intro
                    }, null, 8, _hoisted_17))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.isGridLayout)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "h-100 card-list-image",
              style: _normalizeStyle({ backgroundImage: `url(${ _ctx.item.overviewImageUrl}), url(${(_ctx.isServiceType() ? '/Static/images/default-dienst.png' : '/Static/images/default-training.png')})` })
            }, null, 4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class", "style", "title"])
  ], 8, _hoisted_1))
}