<template>
	<BackButton class="mb-3" :label="$t('expert.button.back')" />

	<div class="row mb-2">
		<div class="col mb-1">
			<Panel class="mb-2" :toggleable="false">
				<template #header>
					<div class="d-flex align-items-center flex-grow-1">
						<div>
							<StatusBadge
								:status="vm.booking.status"
								translation-prefix="enum.expertBookingStatus"
								:type="1"
							/>
						</div>
						<div class="align-items-center d-flex flex-wrap justify-content-between w-100 ml-3">
							<div class="">
								<h1 class="mb-0">
									{{ vm.pageTitle }}
								</h1>
								<p class="mb-0 lead">
									{{ $t("expert.booking.subTitle") }}
								</p>
							</div>
							<div v-if="isExpert && showAcceptOrDeclineButton">
								<Button
									class="p-button-success"
									:label="$t(`common.accept`)"
									@click="onAcceptClick()"
								/>
								<Button
									class="p-button-danger ml-1"
									:label="$t(`common.reject`)"
									@click="onDeclineClick()"
								/>
							</div>
							<div v-if="isExpert && showCompleteButton">
								<Button
									class="p-button-success"
									:label="$t(`common.complete`)"
									@click="onCompleteClick()"
								/>
							</div>
							<div v-if="showCancelButton">
								<Button
									class="p-button-secondary"
									:label="$t(`common.cancel`)"
									@click="onCancelClick()"
								/>
							</div>
							<div v-if="showReviewButton">
								<Button
									class="p-button-primary p-button-outlined"
									:label="$t(`expert.button.${bookingHasReview ? 'seeReview' : 'reviewBooking'}`)"
									@click="onReviewClick()"
								/>
							</div>
							<div v-if="isExpert && showInvoiceButton">
								<Button
									class="p-button-secondary"
									icon="pi pi-file-pdf"
									:label="$t(`expert.button.viewInvoice`)"
									@click="onViewInvoiceClick()"
								/>
							</div>
						</div>
					</div>
				</template>
				<div class="panel-content">
					<div>
						<ProductConfigurationSummaryItem
							v-if="!isExpert"
							:content="vm?.booking?.expertDisplayName"
							:label="$t('expert.booking.expert')"
						/>
						<ProductConfigurationSummaryItem
							v-else
							:content="vm?.booking?.customerContactDisplayName"
							:label="$t('expert.booking.customer')"
						/>
						<ProductConfigurationSummaryItem
							:content="vm?.booking?.workLocation || $t('expert.remote')"
							:label="$t('expert.booking.workLocation')"
						/>
						<ProductConfigurationSummaryItem :label="$t('expert.flexBooking')">
							<template #content>
								<ColumnYesNo :value="vm?.isFlexBooking" />
							</template>
						</ProductConfigurationSummaryItem>
						<ProductConfigurationSummaryItem :label="$t('expert.booking.dates')">
							<template #content>
								<div
									v-for="date in sortedDates(vm?.booking.dates)"
									:key="JSON.stringify(date)"
									class="d-flex flex-column"
								>
									<p class="text-bold mb-0">
										{{ `${formatDate(date.date)} ` }}
									</p>
									<small>{{ `${formatTime(date.start)} - ${formatTime(date.end)}` }}</small>
								</div>
							</template>
						</ProductConfigurationSummaryItem>
					</div>
					<Divider v-if="showExpertReward" :layout="isSmallScreen ? 'horizontal' : 'vertical'" style="flex: 0" />
					<div v-if="showExpertReward">
						<h5>
							{{ isExpert ? $t("expert.booking.reward") : $t("expert.booking.costs") }}
						</h5>
						<div v-if="!isSpinnerVisible('priceSummary')">
							<PriceSummary :vm="getPriceSummary(expertBookingCosts)" />
						</div>
						<div v-else-if="isSpinnerVisible('priceSummary')">
							<i class="pi pi-spin pi-spinner" />
						</div>
					</div>
				</div>
			</Panel>

			<Panel v-if="isExpert" class="mb-2" :toggleable="false">
				<template #header>
					<div class="d-flex">
						<div>
							<h3 class="mb-0">
								{{ $t("expert.booking.titleCustomerInfo") }}
							</h3>
						</div>
					</div>
				</template>
				<div class="panel-content">
					<ProductConfigurationSummaryItem
						:content="vm?.booking?.customerContactDisplayName"
						:label="$t('common.forms.lblName')"
					/>
					<ProductConfigurationSummaryItem :label="$t('common.forms.lblPhoneNumber')">
						<template #content>
							<a :href="`tel:${vm.booking?.expertPhoneNumber}`">
								{{ vm.booking?.expertPhoneNumber }}</a
							>
						</template>
					</ProductConfigurationSummaryItem>
					<ProductConfigurationSummaryItem
						:content="vm?.booking?.customerContactEmail"
						:label="$t('common.forms.lblEmail')"
					>
						<template #content>
							<a :href="`mailto:${vm.booking?.customerContactEmail}`"> {{ vm.booking?.customerContactEmail }}</a>
						</template>
					</ProductConfigurationSummaryItem>
				</div>
			</Panel>

			<Panel v-else class="mb-2" :toggleable="false">
				<template #header>
					<div class="d-flex">
						<div>
							<h3 class="mb-0">
								{{ $t("expert.booking.titleExpertInfo") }}
							</h3>
						</div>
					</div>
				</template>
				<div class="panel-content">
					<div style="flex: 2">
						<div class="d-flex">
							<Avatar
								class="mr-2 profile-image"
								:image="expertProfile?.imageUrl"
								shape="circle"
								size="xlarge"
							/>
							<div>
								<h3 v-if="vm?.expert?.title" class="text-primary mb-1">{{ vm?.expert?.title }}</h3>
								<div class="d-flex align-items-center">
									<PopoverIcon :open-delay="500" placement="right">
										<Avatar
											:alt="vm?.expert?.displayName"
											class="expert-badge"
											:image="vm?.booking?.expertProduct?.iconUrl"
											shape="circle"
										/>
										<template #content>
											<p class="text-bold" v-html="vm?.expert?.displayName" />
											<div v-html="vm?.expert?.description" />
										</template>
									</PopoverIcon>

									<p class="m-0 ml-1">{{ vm?.expert?.function }}</p>
								</div>
								<!-- <div class="d-flex align-items-center mt-1">
										<i class="pi pi-map-marker text-primary" :title="$t('cart.table.iconLocation_title')" />
										<p class="m-0 ml-1 text-bold text-primary">
											{{ `${vm?.expert?.region}, ${vm?.expert?.country}` }}
										</p>
									</div> -->
							</div>
						</div>
					</div>
					<Divider layout="vertical" style="flex: 0" />
					<div style="flex: 3">
						<ProductConfigurationSummaryItem :label="$t('common.forms.lblPhoneNumber')">
							<template #content>
								<a :href="`tel:${vm.booking?.expertPhoneNumber}`">
									{{ vm.booking?.expertPhoneNumber }}</a
								>
							</template>
						</ProductConfigurationSummaryItem>
						<ProductConfigurationSummaryItem
							:content="vm?.booking?.expertEmail"
							:label="$t('common.forms.lblEmail')"
						>
							<template #content>
								<a :href="`mailto:${vm.booking?.expertEmail}`"> {{ vm.booking?.expertEmail }}</a>
							</template>
						</ProductConfigurationSummaryItem>
					</div>
				</div>
			</Panel>

			<Panel class="mb-2" :toggleable="false">
				<template #header>
					<div class="d-flex">
						<div>
							<h3 class="mb-0">
								{{ $t("expert.booking.projectDescription") }}
							</h3>
						</div>
					</div>
				</template>
				<div class="panel-content">
					<div class="text-preline" style="word-break: break-word">
						{{ vm?.booking?.projectDescription }}
					</div>
				</div>
			</Panel>

			<Panel
				v-if="isExpert && !vm?.booking?.isRemote && vm?.booking?.expertCosts.singleTravelDistance"
				class="mb-2"
				:toggleable="false"
			>
				<template #header>
					<div class="align-items-center d-flex justify-content-between w-100">
						<div>
							<h3 class="mb-0">
								{{ $t("expert.booking.workLocation") }}
							</h3>
						</div>
						<Button
							v-if="isExpert"
							class="p-button-primary"
							icon="pi pi-directions"
							:label="$t(`expert.button.routeDescription`)"
							@click="openRouteDescription"
						/>
					</div>
				</template>
				<div class="panel-content w-50">
					<div>
						<ProductConfigurationSummaryItem
							:content="vm?.booking?.workLocation"
							:label="$t('common.forms.lblAddress')"
						/>
						<ProductConfigurationSummaryItem
							:content="`${vm?.booking?.expertCosts.singleTravelDistance} ${$t('common.kilometer')}`"
							:label="$t('expert.booking.distance')"
						/>
					</div>
				</div>
				<div class="map-container">
					<GMapMap :center="{ lat: vm?.booking?.latitude, lng: vm?.booking?.longitude }" :zoom="16" />
				</div>
			</Panel>
		</div>
	</div>

	<ExpertAcceptBookingModal v-if="expertStore.isAcceptBookingModalVisible" @submit="acceptBooking" />
	<ExpertCompleteBookingModal v-if="expertStore.isCompleteBookingModalVisible" @submit="completeBooking" />
	<ExpertDeclineBookingModal
		v-if="expertStore.isCancelOrDeclineBookingModalVisible"
		:type="declineOrCancelModalType"
		@submit="declineOrCancelBooking"
	/>
	<ExpertReviewBookingModal v-if="expertStore.isBookingReviewModalVisible" @submit="reviewBooking" />
	<ExpertReviewBookingDetailsModal v-if="expertStore.isBookingReviewDetailsModalVisible" :vm="vm.booking.expertBookingReview" />
</template>

<script lang="ts">
import Avatar from "primevue/avatar";
import BackButton from "@/components/common/backButton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import Button from "primevue/button";
import ColumnExpertInfo from "@/components/commerce/cart/columnExpertInfo.vue";
import ColumnYesNo from "@/components/commerce/cart/columnYesNo.vue";
import Divider from "primevue/divider";
import ExpertAcceptBookingModal from "@/components/experts/expertAcceptBookingModal.vue";
import ExpertCompleteBookingModal from "@/components/experts/expertCompleteBookingModal.vue";
import ExpertDeclineBookingModal, {
	ExpertCancelOrDeclineBookingModalType,
} from "@/components/experts/expertDeclineBookingModal.vue";
import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
import { AcceptBookingRequest } from "@/types/models/expert/acceptBookingRequest";
import { CompleteBookingRequest } from "@/types/models/expert/completeBookingRequest";
import { CancelBookingRequest } from "@/types/models/expert/cancelBookingRequest";
import { Component, Prop } from "vue-facing-decorator";
import { DeclineBookingRequest } from "@/types/models/expert/declineBookingRequest";
import { ExpertBookingStatus } from "@/types/enum/expertBookingStatus";
import { ExpertCalculationLineType } from "@/types/enum/expertCalculationLineType";
import { ExpertCalculationResponse } from "@/types/models/expert/expertCalculationResponse";
import { ExpertProfileViewModel } from "@/types/models/expert/expertProfileViewModel";
import { ExpertType } from "@/types/enum/expertTypes";
import { IBookingDetail } from "@/types/viewModels/myCadac/bookingDetailViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { orderBy, sortBy } from "lodash";
import ExpertReviewBookingModal, {
	ExpertReviewBookingForm,
} from "@/components/experts/expertReviewBookingModal.vue";
import ExpertReviewBookingDetailsModal from "@/components/experts/expertReviewBookingDetailsModal.vue";
import { ReviewExpertBookingRequest } from "@/types/models/expert/reviewExpertBookingRequest";
import { useExpertStore } from "@/store/expert/expertStore";
import PriceSummary from "@/components/common/priceSummary.vue";
import StatusBadge from "@/components/common/statusBadge.vue";

@Component({
	components: {
		ExpertReviewBookingModal,
		ExpertReviewBookingDetailsModal,
		Avatar,
		BackButton,
		Button,
		ColumnExpertInfo,
		ColumnYesNo,
		PriceSummary,
		StatusBadge,
		Divider,
		ExpertAcceptBookingModal,
		ExpertCompleteBookingModal,
		ExpertDeclineBookingModal,
		ProductConfigurationSummaryItem,
	},
})
export default class BookingDetail extends BaseComponent {
	@Prop({ type: Object as PropType<IBookingDetail>, required: true, default: {} }) vm!: IBookingDetail;
	@Prop({ type: Boolean, required: false, default: false }) isExpert!: boolean;

	declineOrCancelModalType!: ExpertCancelOrDeclineBookingModalType;
	expertStore = useExpertStore();

	get showInvoiceButton(): boolean {
		return (
			(this.vm?.booking?.status == ExpertBookingStatus.Completed ||
				this.vm?.booking?.status === ExpertBookingStatus.Rejected) &&
			this.vm?.booking?.expertType === ExpertType.External
		);
	}

	get expertBookingCosts(): ExpertCalculationResponse {
		return this.vm.booking?.expertCosts as ExpertCalculationResponse;
	}

	get expertProfile(): ExpertProfileViewModel {
		return this.vm.booking?.expertProfile as ExpertProfileViewModel;
	}

	get showAcceptOrDeclineButton(): boolean {
		return this.vm?.booking?.status === ExpertBookingStatus.Requested;
	}

	get showCompleteButton(): boolean {
		return this.vm?.booking?.status === ExpertBookingStatus.InProgress;
	}

	get showCancelButton(): boolean {
		return (
			(this.isExpert && this.vm?.booking?.status === ExpertBookingStatus.Planned) ||
			(!!this.vm?.canCancelFlexBooking &&
				(this.vm?.booking?.status === ExpertBookingStatus.Planned ||
					this.vm?.booking?.status === ExpertBookingStatus.Requested))
		);
	}

	get showReviewButton(): boolean {
		return this.vm?.booking?.status === ExpertBookingStatus.Completed && !this.isExpert;
	}

	get showExpertReward(): boolean {
		return this.vm?.booking?.expertCosts?.lines?.length > 0;
	}

	get bookingHasReview(): boolean {
		return this.vm?.booking?.hasReview;
	}

	openRouteDescription(): void {
		this.openUrl(this.vm.googleMapsDirectionsUrl, "blank");
	}

	onDeclineClick(): void {
		this.declineOrCancelModalType = ExpertCancelOrDeclineBookingModalType.Decline;
		this.expertStore.showModal("CancelOrDeclineBooking");
	}

	onCancelClick(): void {
		this.declineOrCancelModalType = ExpertCancelOrDeclineBookingModalType.Cancel;
		this.expertStore.showModal("CancelOrDeclineBooking");
	}

	onAcceptClick(): void {
		this.expertStore.showModal("AcceptBooking");
	}

	onCompleteClick(): void {
		this.expertStore.showModal("CompleteBooking");
	}

	onReviewClick(): void {
		this.bookingHasReview
            ? this.expertStore.showModal("ReviewBookingDetails")
            : this.expertStore.showModal("ReviewBooking");
	}

	onViewInvoiceClick(): void {
		const queryParams = new URLSearchParams("");
		queryParams.append("bookingId", this.vm.booking.id);
		const url = new URL(`https://${window.location.host}${window.location.pathname}GetPdf`);
		url.search = queryParams.toString();
		this.openUrl(url.toString());
	}

	declineOrCancelBooking(evt: any): void {
		if (this.declineOrCancelModalType === ExpertCancelOrDeclineBookingModalType.Cancel) {
			this.cancelBooking(evt);
		} else {
			this.declineBooking(evt);
		}
	}

	declineBooking({ message }): void {
		const payload: DeclineBookingRequest = {
			bookingId: this.vm.booking.id,
			message: message,
		};
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertbooking/request-decline`, payload)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.rejectBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("CancelOrDeclineBooking");
			});
	}

	cancelBooking({ message }): void {
		const payload: CancelBookingRequest = {
			bookingId: this.vm.booking.id,
			message: message,
		};
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertbooking/request-cancel`, payload)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.cancelBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("CancelOrDeclineBooking");
			});
	}

	acceptBooking(): void {
		const payload: AcceptBookingRequest = {
			bookingId: this.vm.booking.id,
		};
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertbooking/accept`, payload)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.acceptBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("AcceptBooking");
			});
	}

	completeBooking(): void {
		const payload: CompleteBookingRequest = {
			bookingId: this.vm.booking.id,
		};
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertbooking/complete`, payload)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.completeBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("CompleteBooking");
			});
	}

	sortedDates(dates) {
		return orderBy(
			dates,
			function (o) {
				return new Date(o.date);
			},
			["asc"]
		);
	}

	reviewBooking(form: ExpertReviewBookingForm): void {
		const payload: ReviewExpertBookingRequest = {
			bookingId: this.vm.booking.id,
			comment: form.description,
			score: form.score,
			selectedExpertises: form.expertises?.join(","),
			title: form.title,
		};
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertreview`, payload)
			.then(() => {
				window.location.reload();
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("expert.toast.reviewBookingSuccess"),
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.reviewBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.showModal("ReviewBooking");
			});
	}

	getPriceSummary(expertCosts: any): IPriceSummary {
		const { lines, totalPrice, flexBookingPercentage } = expertCosts;
		const additionalLines = new Map<string, number>();
		const standardLines = new Map<string, number>();

		sortBy(lines, "order")
			.filter((x) => x.totalPrice)
			.forEach((x) => {
				if (x.type === ExpertCalculationLineType.fullDay)
					standardLines.set(
						this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity]),
						x.totalPrice
					);
				if (x.type === ExpertCalculationLineType.dayPart)
					standardLines.set(
						this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity]),
						x.totalPrice
					);
				if (x.type === ExpertCalculationLineType.hour)
					standardLines.set(
						this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity]),
						x.totalPrice
					);
			});
		sortBy(lines, "order")
			.filter((x) => x.totalPrice)
			.forEach((x) => {
				if (x.type === ExpertCalculationLineType.travel)
					additionalLines.set(
						this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity, x.price.toFixed(2)]),
						x.totalPrice
					);
				if (x.type === ExpertCalculationLineType.accommodation)
					additionalLines.set(
						x.quantity > 1
							? this.$t(`enum.expertCalculationLineType.${x.type}`) +
									" " +
									this.$t(`expert.nights`, [x.quantity])
							: this.$t(`enum.expertCalculationLineType.${x.type}`) +
									" " +
									this.$t(`expert.night`, [x.quantity]),
						x.totalPrice
					);
				if (x.type === ExpertCalculationLineType.flexBooking)
					additionalLines.set(
						this.$t(`enum.expertCalculationLineType.${x.type}`, [
							flexBookingPercentage,
							x.price.toFixed(2),
						]),
						x.totalPrice
					);
			});

		return { subtotal: totalPrice, additionalLines, standardLines } as IPriceSummary;
	}
}
</script>

<style lang="scss" scoped>
.panel-content {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

	> div {
		display: flex;
		flex: 1;
		flex-direction: column;
	}
}

.expert-badge {
	width: 1.25rem;
	height: 1.25rem;
}

.map-container {
	margin-top: 30px;
	height: 300px;
}

::v-deep(.vue-map-container) {
    height: 100% !important;
}
</style>
