<template>
	<a class="product-card-item" :href="item?.productPageUrl" style="text-decoration: none">
		<Card
			v-if="!isGridLayout"
			class="product-list-card"
			:style="{ backgroundColor: item.backgroundColor }"
			@click="openUrl(item.productPageUrl)"
		>
			<template #content>
				<div class="row h-100">
					<img v-if="showHeaderImage && item.overviewImageVerticalUrl" v-lazy="item.overviewImageVerticalUrl" :alt="item.iconAlt" class="overview-image" :title="item.name" />
					<div class="col-lg-8">
						<div class="d-flex mb-2">
							<div class="align-self-center mr-1"><img v-if="item.iconUrl" v-lazy="item.iconUrl" :alt="item.iconAlt" class="overview-product-icon" :title="item.name" /></div>
							<div class="d-flex flex-column">
								<p class="mb-0 text-muted lead">{{ item.brand }}</p>
								<h4 class="mb-0 pr-5">{{ formatProductName(item.name, item.brand) }}</h4>
							</div>
						</div>
						<p v-if="showIntro" class="mb-0 intro-text text-preline" v-html="item.intro" />
					</div>
					<div class="col-lg-4 mt-2 mt-lg-0 d-flex justify-content-end flex-column">
						<AvatarGroup v-if="isCollectionItem">
							<Avatar v-for="(product, index) in productsSlice(item)" :key="index" :alt="product.iconAlt" :image="product.iconUrl" shape="square" size="large" :title="product.name"></Avatar>
							<Avatar v-if="item.products.length > maxProductsToShow && item.products.length - maxProductsToShow !== 1" class="text-bold mx-1" :label="`+${item.products.length - maxProductsToShow}`" shape="square" size="small"></Avatar>
						</AvatarGroup>
						<p v-if="item.price" class="lead text-muted d-flex flex-wrap text-right justify-content-end">
							<span>
								<span class="">{{ $t("common.from") }}</span>
								<s v-if="item.basePrice !== item.price" class="ml-1 text-faded">{{ formatCurrency(item.basePrice) }}</s>
							</span>
							<span>
								<b class="text-primary ml-1">{{ formatCurrency(item.price) }}</b>
								<span class="" v-if="!isServiceItem">{{ $t("common.pricePerMonth") }}</span>
							</span>
						</p>
						<p v-else-if="(item.availableOnRequest || !item.basePrice) && !item.label" class="lead text-muted text-lg-right">{{ $t("product.availableOnRequest") }}</p>
					</div>
				</div>
			</template>
		</Card>
		<Card
			v-else
			class="product-grid-card"
			:class="{ 'hover-intro': showIntroOnHover, 'product-collection-item': isCollectionItem, 'cross-sell': isCrossSell }"
			:style="{ backgroundColor: isCollectionItem && item.backgroundColor }"
			@click="openUrl(item.productPageUrl)">
			<template v-if="!isMobile && showHeaderImage && item.overviewImageUrl" #header>
				<img v-lazy="item.overviewImageUrl" :alt="item.iconAlt" :title="item.name" />
			</template>
			<template #content>
				<div class="d-flex flex-wrap flex-column h-100 justify-content-between">
					<div class="d-flex justify-content-between flex-wrap-reverse">
						<div class="mr-1"><img v-if="item.iconUrl" v-lazy="item.iconUrl" :alt="item.iconAlt" class="overview-product-icon" :title="item.name" /></div>
						<div class="d-flex flex-column flex-1">
							<p class="mb-0 text-muted lead">{{ item.brand }}</p>
							<h4 class="mb-0">{{ formatProductName(item.name, item.brand) }}</h4>
						</div>
						<Tag v-if="isCrossSell" class="mb-auto ml-auto" :value="$t('enum.itemProductType.' + item.productType)" />
					</div>
					<AvatarGroup v-if="isCollectionItem">
						<Avatar v-for="(product, index) in productsSlice(item)" :key="index" :alt="product.iconAlt" :image="product.iconUrl" shape="square" size="large" :title="product.name"></Avatar>
						<Avatar v-if="item.products.length > maxProductsToShow && item.products.length - maxProductsToShow !== 1" class="text-bold mx-1" :label="`+${item.products.length - maxProductsToShow}`" shape="square" size="small"></Avatar>
					</AvatarGroup>
					<p v-if="item.price" class="price-text lead text-muted d-flex flex-wrap text-right justify-content-end mb-0">
						<span>
							<span class="">{{ $t("common.from") }}</span>
							<s v-if="item.basePrice !== item.price" class="ml-1 text-faded">{{ formatCurrency(item.basePrice) }}</s>
						</span>
						<span>
							<b class="text-primary ml-1">{{ formatCurrency(item.price) }}</b>
							<span class="" v-if="!isServiceItem">{{ $t("common.pricePerMonth") }}</span>
						</span>
					</p>
					<p v-else-if="(item.availableOnRequest || !item.basePrice) && !item.label" class="lead price-text text-muted text-lg-right pr-2">{{ $t("product.availableOnRequest") }}</p>
				</div>
			</template>
			<template #footer>
				<p v-if="showIntro" class="mb-0 intro-text text-preline pr-2" v-html="item.intro" />
				<div v-else-if="item.label" class="promotion">{{ item.label }}</div>
			</template>
		</Card>
	</a>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Tag from "primevue/tag";
import { CollectionItemViewModel } from "@/types/models/software/collectionItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { ItemProductType } from "@/types/enum/itemProductType";
import { PropType } from "vue";
import { ServiceItemViewModel } from "../../types/models/service/serviceItemViewModel";

@Component({ components: { Tag } })
export default class ProductOverviewItemCard extends BaseComponent {
    @Prop({ type: Object as PropType<ServiceItemViewModel | CollectionItemViewModel>, required: true, default: {} })
    item!: ServiceItemViewModel | CollectionItemViewModel;

	@Prop({ type: String, required: true, default: "grid" }) layout!: string;
	@Prop({ type: Boolean, required: false, default: false }) showHeaderImage!: boolean;
	@Prop({ type: Boolean, default: false, required: false }) isCrossSell!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) showIntro!: boolean;

	get isCollectionItem(): boolean {
		return this.item.productType === ItemProductType.Collection;
	}

    get isServiceItem(): boolean {
        return this.item.productType === ItemProductType.Service;
    }

	get showIntroOnHover(): boolean {
		return !!this.item.intro && this.showIntro && !this.isSmallScreen;
	}

	get isGridLayout(): boolean {
		return this.layout === "grid";
	}

	get maxProductsToShow(): number {
		if (this.isCrossSell) return 3;

		if (this.isMobile) {
			return this.isGridLayout ? 3 : 2;
		}

		if (this.isTablet) {
			return this.isGridLayout ? 4 : 6;
		}

		if (this.isSmallLargeScreen) {
			return this.isGridLayout ? 3 : 5;
		}

		if (this.isLargeScreen) {
			return this.isGridLayout ? 4 : 5;
		}

		if (this.isExtraLargeScreen) {
			return this.isGridLayout ? 4 : 5;
		}

		return 5;
	}

    productsSlice(item: CollectionItemViewModel): ServiceItemViewModel[] {
		// prevent showing +1
		const sliceCount =
			item.products.length - this.maxProductsToShow === 1
				? this.maxProductsToShow + 1
				: this.maxProductsToShow;
		const productsSlice = item.products.slice(0, sliceCount);

		return productsSlice;
	}
}
</script>

<style scoped lang="scss">
.product-card-item {
	position: relative;
	display: block;
	height: 100%;
}

.price-text {
	font-size: 1.05rem;
}

.promotion {
	width: 100%;
	padding: 0.25rem 0;
	font-weight: 600;
	font-size: 1.2rem;
	letter-spacing: 0.5px;
	text-align: center;
	color: var(--primary-color-text);
	background: rgb(145, 0, 75);
	background: radial-gradient(circle, rgba(145, 0, 75, 1) 50%, rgba(93, 0, 48, 1) 100%);
	border-top: 2px solid var(--surface-d);
	text-transform: uppercase;
}

::v-deep(.product-list-card) {
	margin: 0.75rem;
	position: relative;

	.intro-text {
		display: -webkit-box;
		-webkit-line-clamp: 7;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.overview-image {
		width: 250px;
		max-height: 300px;
		margin-right: 1rem;
	}

	.overview-product-icon {
		height: 67px;
		width: 67px;
	}

	&.p-card {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		border: var(--surface-border);
		transition: all 0.4s;

		&:hover {
			box-shadow:
				4px 4px 2px 0 rgb(0 0 0 / 20%),
				0 1px 4px 0 rgb(0 0 0 / 14%),
				0 2px 8px 0 rgb(0 0 0 / 12%);
			cursor: pointer;
		}

		.p-card-body {
			padding: 0 1rem;
			flex: 1;
		}

		.p-card-content {
			height: 100%;
		}
	}

	.p-avatar-group {
		margin: 0.5rem 0 auto 0;
		padding: 0.5rem 0;
		background: rgba(255, 255, 255, 0.25);
		width: 100%;
		flex-wrap: wrap;

		.p-avatar {
			border: none;
			margin: 0.75rem;
			width: 3.5rem;
			height: 3.5rem;

			img {
				transition: transform 0.3s ease-in-out;

				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}
}

::v-deep(.product-grid-card) {
	.overview-product-icon {
		height: 67px;
		width: 67px;
		margin-right: 0.5rem;
	}

	.intro-text {
		display: none;
	}

	&.cross-sell.p-card {
		p.lead {
			font-size: 1.1rem;
		}

		h4,
		.p-avatar-text {
			font-size: 1.25rem;
		}

		.overview-product-icon {
			height: 54px;
			width: 54px;
		}

		.p-avatar {
			height: 44px;
			width: 44px;
		}

		.p-card-content {
			height: 100%;
		}

		.p-card-footer {
			padding: 0;
		}
	}

	&.p-card {
		border: var(--surface-border);
		transition: all 0.4s;
		position: relative;
		overflow: hidden;
		height: 100%;

		.p-card-body {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			padding: 0;
		}

		&.hover-intro {
			height: 400px;

			&.product-collection-item {
				height: 525px;
			}

			.p-card-header,
			.p-card-content,
			.p-card-footer {
				transition: transform 0.7s ease-in-out;
			}

			&:hover {
				.p-card-header,
				.p-card-content,
				.p-card-footer {
					transform: translateY(-225px);
					transition: transform 0.35s ease-in-out;
				}

				.intro-text {
					display: -webkit-box;
					-webkit-line-clamp: 9;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}
		}

		.p-card-header {
			img {
				height: 225px;
				object-fit: cover;
			}
		}

		.p-card-content {
			padding: 1rem;
			min-height: 150px;
			height: 100%;
		}

		.p-card-footer {
			padding: 0;
		}

		&:hover {
			box-shadow:
				8px 4px 10px -1px rgb(0 0 0 / 12%),
				0px 4px 10px 0px rgb(0 0 0 / 8%),
				0px 1px 4px 4px rgb(0 0 0 / 8%);
			cursor: pointer;
		}
	}

	.p-avatar-group {
		margin: 0.5rem 0;
		padding: 0.5rem;
		background: rgba(255, 255, 255, 0.25);
		width: fit-content;

		.p-avatar {
			border: none;
			margin: 0;

			img {
				transition: transform 0.3s ease-in-out;

				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}
}
</style>
