import axios from 'axios';
import localazyMetadata from '@/types/generated/localazyMetaData';
import { FormatHelper } from '@/types/helpers/formatHelper';
import { createI18n } from 'vue-i18n';
import { values } from 'lodash';


/// Returns the first part of the language
/// eg. en-GB return 'en'
export function getActiveLocale() {
	const splitLang = getActiveLanguage().split('-');
	const lang = splitLang.length > 1 ? splitLang[0] : getActiveLanguage();
	//Map exclusion for certain languages.
	switch (lang) {
		case 'no':
			return 'nb';
		default:
			return lang;
	}
}

/// Returns the full language
export function getActiveLanguage() {
	return document.documentElement.lang;
}

/**
 * Load translations from local files (fallback)
 * 
 * This function loads translation messages for the active locale from local files.
 * 
 * @returns translations
 */
export function loadLocalMessagesForActiveLocale() {
	const messages = {};
	try {
		messages[getActiveLocale()] = require(`../../assets/i18n/${getActiveLocale()}.json`);
	} catch {
		console.error(`Local translation file for ${getActiveLocale()} not found.`);
	}
	return messages;
}

/**
 * Load translations from TranslationController
 * 
 * This function loads translation messages for the active locale from the TranslationController.
 * 
 * @returns Promise with translations
 */
export async function loadMessagesFromTranslationController() {
	try {
		const response = await axios.get(`/api/${getActiveLocale()}/translation/stored`);
		return response.data;
	} catch (error) {
		console.error(
			`Unable to load translations from TranslationController, falling back to local files for locale: ${getActiveLocale()}.`,
			error
		);
		return Promise.resolve(loadLocalMessagesForActiveLocale());
	}
}

/**
 * Load translations from Localazy
 * 
 * This function loads translations from Localazy using the Localazy API and metadata. If the API and CDN are enabled, it will attempt to load the translations from the CDN first, then fall back to the API. If the API and CDN are disabled, it will load the translations from the local files.
 * 
 * @returns translations
 */
export async function loadPrimeVueLocale() {
	const messages = await import(`../../assets/i18n/${getActiveLocale()}.json`);
	const primeVueMessages = messages['primeVue'] || {};
	const commonMessages = messages['common'] || {};
	const activeLocale =
		values(FormatHelper.dateLocales).find((x) => x.code === getActiveLanguage()) ||
		FormatHelper.dateLocales[getActiveLocale()] ||
		FormatHelper.dateLocales['gb'];
	if (!activeLocale) return primeVueMessages;
	// Set some dynamic translations used in PrimeVue
	primeVueMessages['dateFormat'] = activeLocale.formatLong
		.date({ width: 'short' })
		?.toLowerCase()
		?.replace('yyyy', 'yy')
		?.replace('y', 'yy');
	primeVueMessages['firstDayOfWeek'] = activeLocale.options.weekStartsOn;
	primeVueMessages['dayNames'] = [...Array(7).keys()].map((i) =>
		activeLocale.localize.day(i, { width: 'wide' })
	);
	primeVueMessages['dayNamesMin'] = [...Array(7).keys()].map((i) =>
		activeLocale.localize.day(i, { width: 'short' })
	);
	primeVueMessages['dayNamesShort'] = [...Array(7).keys()].map((i) =>
		activeLocale.localize.day(i, { width: 'abbreviated' })
	);
	primeVueMessages['monthNamesShort'] = [...Array(12).keys()].map((i) =>
		activeLocale.localize.month(i, { width: 'abbreviated' })
	);
	primeVueMessages['monthNames'] = [...Array(12).keys()].map((i) =>
		activeLocale.localize.month(i, { width: 'wide' })
	);
	primeVueMessages['reject'] = commonMessages['no'] || '';
	primeVueMessages['accept'] = commonMessages['yes'] || '';
	primeVueMessages['cancel'] = commonMessages['cancel'] || '';

	return primeVueMessages;
}

/**
 * Load translations from Localazy
 * 
 * This function loads translations from Localazy using the Localazy API and metadata. If the API and CDN are enabled, it will attempt to load the translations from the CDN first, then fall back to the API. If the API and CDN are disabled, it will load the translations from the local files.
 * 
 * @returns translations
 */
const i18nConfig = {
	locale: getActiveLocale() || 'nl',
	fallbackLocale: localazyMetadata?.baseLocale || 'en',
	fallbackWarn: false,
	messages: loadLocalMessagesForActiveLocale(),
};
export default createI18n(i18nConfig);
