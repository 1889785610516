<template>
	<div class="container-fluid product-header-bg" :style="{ background: vm.backgroundColor }">
		<slot name="breadcrumbs" />
		<div class="mt-3 p-3">
			<div class="d-flex flex-column flex-lg-row mb-2">
				<div v-if="vm.iconUrl" class="product-header-icon align-self-lg-center">
					<img v-lazy="vm.iconUrl" :alt="vm.iconAlt || vm.name" :title="vm.name" />
				</div>
				<div class="d-flex flex-column">
					<p class="mb-0 text-muted lead">
						{{ showOverviewImage() ? $t('enum.itemProductType.' + vm.productType) : vm.brand }}
					</p>
					<h1 v-if="(userProfile?.isCadacSales || userProfile?.isImpersonated) && vm.crmUrl" class="mb-0">
						<a :href="vm.crmUrl" target="_blank" :title="$t('common.goToCrm')">
							{{ formatProductName(vm.name, vm.brand) }}
						</a>
					</h1>
					<h1 v-else class="mb-0">
						{{ formatProductName(vm.name, vm.brand) }}
					</h1>
				</div>
			</div>
			<Chip v-if="vm.price" class="p-chip-primary">
				<span class="mr-1">{{ $t("common.from") }}</span>
				<i18n-t keypath="common.pricePerMonth">
					<b class="mr-1">{{ formatCurrency(vm.price) }}</b>
				</i18n-t>
			</Chip>

			<p class="lead text-preline mt-1 product-intro" v-html="vm.intro" />

			<Button v-if="showTeachingsMaterialButton && vm.description" icon="pi pi-info-circle" :label="$t('product.lblShowTeachingsMaterialButton')" @click="visible = true" id="productInfoBtn" />
			<Dialog v-model:visible="visible" modal :header="`${$t('training.dialog.lblHeaderTitle')} ${formatProductName(vm.name, vm.brand)}`" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
				<p class="lead rte mt-1 product-intro" v-html="vm.description" />
			</Dialog>
		</div>
		<div v-if="vm.usps?.length" class="row pt-2">
			<div v-for="usp in vm.usps" :key="usp" class="col-auto d-flex flex-wrap align-items-center mb-2">
				<font-awesome-icon class="text-success mr-2" :icon="['far', 'check-circle']" size="2x" />
				<b class="text-primary">{{ usp }}</b>
			</div>
		</div>
		<div v-if="vm.relatedProducts?.length">
			<p class="lead text-bold">{{ $t("product.lblProductsInCollection") }}</p>
			<Carousel :num-scroll="2"
					  :num-visible="3"
					  :value="vm.relatedProducts"
					  :responsiveOptions="responsiveOptions">
				<template #item="slotProps">
					<a class="d-flex flex-column align-items-center"
					   :href="slotProps.data.productPageUrl"
					   style="text-decoration: none">
						<Avatar v-if="slotProps.data.iconUrl"
								:alt="slotProps.data.iconAlt || slotProps.data.name"
								:image="slotProps.data.iconUrl"
								size="large"
								:title="slotProps.data.name" />

						<p class="text-bold mb-0">
							{{ formatProductName(slotProps.data.name, slotProps.data.brand) }}
						</p>
					</a>
				</template>
			</Carousel>
		</div>
	</div>
	<div v-if="showOverviewImage()" class="header-image">
		<img @error="setDefaultImage" v-lazy="vm.overviewImageUrl || (isServiceType() ? '/Static/images/default-dienst.png' : '/Static/images/default-training.png')" class="w-100" />
	</div>
</template>

<script lang="ts">
	import BaseComponent from "@/components/base/baseComponent.vue";
	import Carousel from "primevue/carousel";
	import Dialog from "primevue/dialog";
	import Chip from "primevue/chip";
	import { Component, Prop } from "vue-facing-decorator";
	import { PropType } from "vue";
	import { ItemProductType } from "../../types/enum/itemProductType";
	import { ServiceItemViewModel } from "../../types/models/service/serviceItemViewModel";

	export interface IProductPageHeader {
		backgroundColor?: string;
		brand: string;
		crmUrl?: string;
		iconAlt: string;
		iconUrl: string;
		intro: string;
		name: string;
		price: number;
        relatedProducts?: ServiceItemViewModel[];
		usps?: string[];
		descriptionTitle: string;
		description: string;
		productType?: ItemProductType;
		overviewImageUrl?: string;
	}

	@Component({ components: { Chip, Carousel, Dialog } })
	export default class ProductPageHeader extends BaseComponent {
		@Prop({ type: Object as PropType<IProductPageHeader>, required: true, default: {} }) vm!: IProductPageHeader;
		@Prop({ type: Boolean, required: false, default: false }) showTeachingsMaterialButton!: boolean;

		visible = false;

		showOverviewImage() {
            return this.vm?.overviewImageUrl && (this.vm?.productType === ItemProductType.Service || this.vm?.productType === ItemProductType.Training)
        }

		isServiceType(): boolean {
			return this.vm?.productType === ItemProductType.Service;
		}

		setDefaultImage(event) {
			event.target.src = this.isServiceType() ? '/Static/images/default-dienst.png' : '/Static/images/default-training.png';
		};

		responsiveOptions = [
			{
				breakpoint: '1440px',
				numVisible: 3,
				numScroll: 1,
			},
			{
				breakpoint: '992px',
				numVisible: 2,
				numScroll: 1,
			},
			{
				breakpoint: '576px',
				numVisible: 1,
				numScroll: 1,
			},
		];
	}
</script>

<style lang="scss" scoped>
	.product-intro {
		margin-bottom: 1rem;
	}

    .product-header-bg {
        background: #F3F7FA;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 100%;
            width: 200%;
            height: 100%;
            /*background: inherit;*/
        }
    }

	.product-header-icon {
		display: flex;
		align-items: center;
		align-self: start;
		background: transparent;
		border-radius: 50%;
		height: fit-content;
		margin-right: 1rem;

		img {
			object-fit: contain;
			flex: 0;
			height: 73px;
			width: 73px;
		}
	}

	:deep(.p-carousel-container) {
		background: rgba(255, 255, 255, 0.25);
		padding: 0.75rem;
	}

    .header-image {
        height: 200px;
        overflow: hidden;
    }
</style>
