<template>
	<MainNavBar v-if="!vm?.hideMenuAndFooter" />
	<slot />
	<MainFooter v-if="!vm?.hideMenuAndFooter" />
	<ConfirmDialog :breakpoints="{ '768px': '70vw', '576px': '90vw' }" :style="{ width: '30vw' }" />
	<DynamicDialog
		:breakpoints="{
			'2000px': '35vw',
			'1600px': '40vw',
			'1200px': '50vw',
			'992px': '60vw',
			'768px': '75vw',
			'576px': '90vw',
		}"
		:modal="true"
		:style="{ width: '30vw' }"
	/>
	<Toast :auto-z-index="false" :base-z-index="1001" position="top-right" />
	<div v-if="isLoading" class="p-dialog-mask p-component-overlay loading-overlay">
		<ProgressSpinner animation-duration="1.75s" stroke-width="3.5" />
	</div>

	<!--	JSON For Linked Data (Displays additional info to google search results) -->
	<teleport to="head">
		<component :is="'script'" type="application/ld+json">
			{{ breadCrumbsLinkedData }}
		</component>
		<component :is="'script'" type="application/ld+json">
			{{ searchLinkedData }}
		</component>
	</teleport>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ConfirmDialog from "primevue/confirmdialog";
import DynamicDialog from "primevue/dynamicdialog";
import MainFooter from "@/components/common/menu/mainFooter.vue";
import MainNavBar from "@/components/common/menu/mainNavBar.vue";
import ProgressSpinner from "primevue/progressspinner";
import Toast from "primevue/toast";
import i18n, { loadPrimeVueLocale } from "@/types/utils/i18n";
import { Component, Prop } from "vue-facing-decorator";
import { IMainLayout } from "@/types/viewModels/common/mainLayoutViewModel";
import { IntlNumberFormat } from "vue-i18n";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { usePrimeVue } from "primevue/config";
import { FormData } from "@/types/models/googleAnalytics/properties/formData";
import { entries } from "lodash";
import { GaEvent } from "@/types/models/googleAnalytics/enum/gaEvent";
import { BooleanString } from "@/types/enum/boolean";
import { pushToGaDataLayer } from "@/types/utils/ga4";
import { hash } from "@/types/helpers/hashHelper";

@Component({
	components: {
		MainNavBar,
		MainFooter,
		ConfirmDialog,
		ProgressSpinner,
		Toast,
		DynamicDialog,
	},
})
/// Wrapper component for views
export default class MainLayout extends BaseComponent {
	@Prop({ type: Object as PropType<IMainLayout>, required: true, default: {} }) vm!: IMainLayout;

	breadCrumbsLinkedData;
	searchLinkedData;

	created(): void {
		this.layoutStore.vm = this.vm;
		Log.info("MainLayoutVm", this.vm);
		this.initSeoData();
		const primeVue = usePrimeVue();
		loadPrimeVueLocale().then((messages) => {
			primeVue.config.locale = messages;
		});
		i18n.global.setNumberFormat(this.currentRegion, this.getNumberFormatForCurrentCurrency());

		if (this.layoutStore.isLoggedIn) {
			const trackVerified = !!localStorage.getItem("trackVerified");
			if (!this.layoutStore.isAuthenticated && !trackVerified) localStorage.setItem("trackVerified", "true");
			if (this.layoutStore.isAuthenticated && trackVerified) {
				this.setDataLayer.accountVerified(this.userProfile);
				localStorage.removeItem("trackVerified");
			}
		}
	}

	mounted(): void {
		if (this.vm.impersonationErrorMessage)
			this.$toast.add({
				severity: "error",
				detail: this.vm.impersonationErrorMessage,
				life: 300000,
			});


		window.addEventListener("chat-loaded", () => {
			this.layoutStore.chatWidgetAvailable = window.fcWidget.isInitialized();
		});

		document.documentElement.style.setProperty("--navbarHeightInPx", `${this.layoutStore.navBarHeight}px`);
		this.initDataLayerListeners();

		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		if (params && params.dl_track === BooleanString.True && params.type === GaEvent.NewsletterSubscribe) {
			this.handleNewsletterSubscribeTracking(params.email);
		}
	}

	initDataLayerListeners(): void {
		window.addEventListener("track-form-submit", (event) => {
			this.handleFormTracking((event as any).detail);
		});
		window.addEventListener("track-upload-quotation", () => {
			this.setDataLayer.uploadQuotation(this.layoutStore.vm?.currentUser);
		});
		window.addEventListener("track-error-page", (event: any) => {
			this.setDataLayer.errorEvent(event.detail);
		});

		if (!this.layoutStore.isLoggedIn) {
			localStorage.setItem("trackLogin", "true");
		} else if (localStorage.getItem("trackLogin")) {
			this.setDataLayer.loginEvent(this.userProfile);
			localStorage.removeItem("trackLogin");
		}
	}

	async handleNewsletterSubscribeTracking(email: string): Promise<void> {
		const currentUser = this.layoutStore.vm?.currentUser;
		pushToGaDataLayer.newsLetterSubscribe({
			event: GaEvent.NewsletterSubscribe,
			customer: {
				login_state: currentUser?.email ? "true" : "false",
				user_id: currentUser?.email ? currentUser.cadacAccountId : null,
				user_email_hashed: currentUser?.emailHash ? currentUser?.emailHash : await hash(email),
				user_email: currentUser.email ? currentUser.email : null,
			},
		});
	}

	handleFormTracking(form: FormData): void {
		switch (form.form_type?.toLowerCase()) {
			case "contact email":
				this.setDataLayer.newsLetterSubscribe(this.layoutStore.vm?.currentUser);
				break;
			case "demo request":
			case "training request":
			case "service request":
			case "software request":
			case "trial request":
			case "trial download":
				this.setDataLayer.generateLead(this.layoutStore.vm?.currentUser, form);
				break;
			default:
				this.setDataLayer.generateLead(this.layoutStore.vm?.currentUser, form);
		}
	}

	get isLoading(): boolean {
		return this.loadingStore.loadingCount > 0;
	}

	private getNumberFormatForCurrentCurrency(): IntlNumberFormat {
		return {
			currency: {
				style: "currency",
				currency: this.vm.currentCurrency,
				currencyDisplay: "symbol",
			},
		};
	}

	initSeoData(): void {
		this.breadCrumbsLinkedData = {
			"@context": "https://schema.org",
			"@type": "BreadcrumbList",
			itemListElement: entries(this.vm.seo?.breadCrumbs)?.map(([key, value], index) => ({
				"@type": "ListItem",
				position: index + 1,
				name: key,
				item: value,
			})),
		};

		this.searchLinkedData = {
			"@context": "https://schema.org",
			"@type": "WebSite",
            "name": 'Cadac Group | Enabling digital. | Autodesk Platinum Partner',
			url: location.origin,
			potentialAction: {
				"@type": "SearchAction",
				target: {
					"@type": "EntryPoint",
					urlTemplate: `${location.origin}${this.vm.searchPageUrl}?type=all&query={search_term_string}`,
				},
				"query-input": "required name=search_term_string",
			},
		};
	}
}
</script>

<style lang="scss">
.loading-overlay {
	background-color: rgba(0, 0, 0, 0.08);
	z-index: 9999;
}

.p-toast {
	z-index: 1002;
}
</style>
