import AccountSettings from '@/views/myCadac/accountSettings.vue';
import AdvisorInfo from '@/views/commerce/advisorInfo.vue';
import BookingDetail from '@/views/myCadac/bookingDetail.vue';
import Checkout from '@/views/commerce/checkout.vue';
import CheckoutComplete from '@/views/commerce/checkoutComplete.vue';
import ContentCategoryDetail from '@/views/general/contentCategoryDetail.vue';
import ContentCategoryOverview from '@/views/general/contentCategoryOverview.vue';
import ContractDetail from '@/views/myCadac/contractDetail.vue';
import ContractExtend from '@/views/myCadac/contractExtend.vue';
import ContractOverview from '@/views/myCadac/contractOverview.vue';
import DigitalAdvisor from '@/views/myCadac/digitalAdvisor.vue';
import ExpertAvailability from '@/views/myCadac/expertAvailability.vue';
import ExpertAccountPage from '@/views/commerce/expertAccountPage.vue';
import ExpertBookingOverview from '@/views/myCadac/expertBookingOverview.vue';
import ExpertDetail from '@/views/commerce/expertDetail.vue';
import ExpertOverview from '@/views/commerce/expertOverview.vue';
import ExpertSettings from '@/views/myCadac/expertSettings.vue';
import ExpertTravelSettings from '@/views/myCadac/expertTravelSettings.vue';
import FinishedTrainingDetail from '@/views/myCadac/finishedTrainingDetail.vue';
import FinishedTrainingOverview from '@/views/myCadac/finishedTrainingOverview.vue';
import LicenceManagementDetail from '@/views/myCadac/licenceManagementDetail.vue';
import LicenceManagementOverview from '@/views/myCadac/licenceManagementOverview.vue';
import MainLayout from '@/views/layout/mainLayout.vue';
import MyCadacLayout from '@/views/layout/myCadacLayout.vue';
import MyCadacMenu from '@/components/common/menu/myCadacMenu.vue';
import OrderOverview from '@/views/myCadac/orderOverview.vue';
import PlannedTrainingDetail from '@/views/myCadac/plannedTrainingDetail.vue';
import PlannedTrainingOverview from '@/views/myCadac/plannedTrainingOverview.vue';
import SavedCartsOverview from '@/views/myCadac/savedCartsOverview.vue';
import RewardsPage from '@/views/myCadac/rewardsPage.vue';
import SearchPage from '@/views/general/searchPage.vue';
import ServiceOverview from '@/views/myCadac/serviceOverview.vue';
import ServicePassProduct from '@/views/commerce/servicePassProduct.vue';
import ServiceProduct from '@/views/commerce/serviceProduct.vue';
import ShoppingCart from '@/views/commerce/shoppingCart.vue';
import SoftwareProduct from '@/views/commerce/softwareProduct.vue';
import SoftwareProductOverview from '@/views/commerce/softwareProductOverview.vue';
import SupportAskQuestion from '@/views/commerce/supportAskQuestion.vue';
import SupportFaq from '@/views/commerce/supportFaq.vue';
import SupportFaqArticle from '@/views/commerce/supportFaqArticle.vue';
import SupportOverview from '@/views/myCadac/supportOverview.vue';
import SupportProduct from '@/views/commerce/supportProduct.vue';
import SupportTicketOverview from '@/views/myCadac/supportTicketOverview.vue';
import SupportTicketProduct from '@/views/commerce/supportTicketProduct.vue';
import TrainingProduct from '@/views/commerce/trainingProduct.vue';
import WalletOverview from '@/views/myCadac/walletOverview.vue';
import WalletProduct from '@/views/commerce/walletProduct.vue';
import MicroServiceProduct from '@/views/commerce/microServiceProduct.vue';
import QuotationRequest from '@/views/commerce/quotationRequest.vue';

/*** Global components ***/
const overviewViews = [
	{ component: ServiceOverview, element: 'service-overview' }, //ServiceOverview
	{ component: ServiceOverview, element: 'training-product-overview' }, //TrainingProductOverview
	{ component: SoftwareProductOverview, element: 'software-product-overview' },
	{ component: ExpertOverview, element: 'expert-overview' },
	{ component: SupportTicketOverview, element: 'support-ticket-overview' },
	{ component: SupportOverview, element: 'support-overview' },
	{ component: ContractOverview, element: 'contract-overview' },
	{ component: PlannedTrainingOverview, element: 'planned-training-overview' },
	{ component: FinishedTrainingOverview, element: 'finished-training-overview' },
	{ component: SavedCartsOverview, element: 'saved-carts-overview' },
	{ component: ExpertBookingOverview, element: 'expert-booking-overview' },
	{ component: OrderOverview, element: 'order-overview' },
	{ component: WalletOverview, element: 'wallet-overview' },
	{ component: LicenceManagementOverview, element: 'licence-management-overview' },
];

const softwareViews = [
	{ component: SoftwareProduct, element: 'software-product' },
];

const trainingViews = [
	{ component: TrainingProduct, element: 'training-product' },
];

const serviceViews = [
	{ component: ServiceProduct, element: 'service-product' },
];

const expertViews = [
	{ component: ExpertDetail, element: 'expert-detail' },
	{ component: ExpertSettings, element: 'expert-settings' },
	{ component: ExpertTravelSettings, element: 'expert-travel-settings' },
	{ component: ExpertAvailability, element: 'expert-availability' },
	{ component: ExpertAccountPage, element: 'expert-account-page' },
];
const contractViews = [
	{ component: ContractDetail, element: 'contract-detail' },
	{ component: ContractExtend, element: 'contract-extend' },
];

const supportViews = [
	{ component: SupportTicketProduct, element: 'support-ticket-product' },
	{ component: SupportAskQuestion, element: 'support-ask-question' },
	{ component: SupportProduct, element: 'support-product' },
	{ component: SupportFaq, element: 'support-faq' },
	{ component: SupportFaqArticle, element: 'support-faq-article' },
];

const webshopProcessViews = [
	{ component: ShoppingCart, element: 'shopping-cart' },
	{ component: Checkout, element: 'checkout' },
	{ component: CheckoutComplete, element: 'checkout-complete' },
];

const myCadacViews = [
	{ component: MyCadacLayout, element: 'my-cadac-layout' },
	{ component: MyCadacMenu, element: 'my-cadac-menu' },
	{ component: AccountSettings, element: 'account-settings' },
	{ component: RewardsPage, element: 'rewards-page' },
];

export const views = [
	...overviewViews,
	...softwareViews,
	...trainingViews,
	...serviceViews,
	...expertViews,
	...contractViews,
	...supportViews,
	...webshopProcessViews,
	...myCadacViews,
	{ component: MainLayout, element: 'main-layout' },
	{ component: SearchPage, element: 'search-page' },
	{ component: DigitalAdvisor, element: 'digital-advisor' },
	{ component: PlannedTrainingDetail, element: 'planning-training-detail' },
	{ component: FinishedTrainingDetail, element: 'finished-training-detail' },
	{ component: LicenceManagementDetail, element: 'licence-management-detail' },	
	{ component: BookingDetail, element: 'booking-detail' },
	{ component: ServicePassProduct, element: 'service-pass-product' },
	{ component: WalletProduct, element: 'wallet-product' },
	{ component: AdvisorInfo, element: 'advisor-info' },
	{ component: ContentCategoryOverview, element: 'content-category-overview' },
	{ component: ContentCategoryDetail, element: 'content-category-detail' },
	{ component: MicroServiceProduct, element: 'micro-service-product' },
	{ component: QuotationRequest, element: 'quotation-request' },
];
