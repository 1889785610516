<template>
    <Dialog v-model:visible="isVisible"
            :breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
            :closable="true"
            :header="vm.title"
            :modal="true"
            :style="{ width: '30vw' }"
            @hide="onCancel()">
        <div>
            <div>
                <div class=" py-1 px-2">
                    <h3 class="my-1">{{ vm?.intervieweeName || '-' }}</h3>
                </div>
                <div class=" p-1">
                    <Rating v-model="vm.score" :stars="5" readonly="true" :cancel="false" />
                </div>
                <div class=" py-1 px-2">
                    <p class="">{{ vm?.created ? formatDate(vm.created) : '-' }}</p>
                </div>
            </div>

            <hr class="m-1" />

            <div>
                <div class=" py-1 px-2">
                    <label class="mb-0">{{ $t("expert.reviewDetailModal.title") }}:</label>
                    <p class="mb-0">{{ vm?.title || '-' }}</p>
                </div>
                <div class=" py-1 px-2">
                    <label class="mb-0">{{ $t("expert.reviewDetailModal.comment") }}:</label>
                    <p class="mb-0" v-html="vm?.comment || '-'" />
                </div>
                <div class=" py-1 px-2">
                    <label class="mb-0">{{ $t("expert.reviewDetailModal.tags") }}:</label>
                    <br />
                    <Tag v-for="tag in vm.tags" :key="`${tag}`" class="mb-auto ml-auto" :value="tag" />
                </div>
            </div>
        </div>

        <template #footer>
            <Button class="p-button-text p-button-plain" :label="$t('common.close')" @click="onCancel()" />
        </template>
    </Dialog>
</template>

<script lang="ts">
    import BaseComponent from "@/components/base/baseComponent.vue";
    import { Component, Prop } from "vue-facing-decorator";
    import { PropType } from "vue";
    import { ExpertReviewBookingForm } from "./expertReviewBookingModal.vue";
    import { useExpertStore } from "../../store/expert/expertStore";
    import Tag from "primevue/tag";
    import Rating from "primevue/rating";

    @Component({
        components: {
            Tag,
            Rating,
        },
    })
    export default class ExpertReviewBookingDetailsModal extends BaseComponent {
        @Prop({ type: Object as PropType<ExpertReviewBookingForm>, required: true, default: {} })
        vm!: ExpertReviewBookingForm;
        expertStore = useExpertStore();
        isVisible = true;
        onCancel(): void {
            this.expertStore.hideModal("ReviewBookingDetails"); 
        }
    }
</script>
