import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col mb-2" }
const _hoisted_4 = {
  key: 0,
  class: "display-4 mb-0"
}
const _hoisted_5 = {
  key: 1,
  class: "lead"
}
const _hoisted_6 = { class: "row gap-y-3 align-items-stretch" }
const _hoisted_7 = { class: "row mt-4" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CallToActionOfferBlock = _resolveComponent("CallToActionOfferBlock")!
  const _component_BaseProductOverviewItemCard = _resolveComponent("BaseProductOverviewItemCard")!
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.vm.title)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.vm.title), 1))
            : _createCommentVNode("", true),
          (_ctx.vm.subtitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.vm.subtitle), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.vm.ctaOffer)
        ? (_openBlock(), _createBlock(_component_CallToActionOfferBlock, {
            key: 0,
            vm: _ctx.vm.ctaOffer
          }, null, 8, ["vm"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.products, (product) => {
          return (_openBlock(), _createElementBlock("div", {
            key: product,
            class: "col col-12 col-lg-6"
          }, [
            _createVNode(_component_BaseProductOverviewItemCard, { vm: product }, null, 8, ["vm"])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.vm.overviewButtonCTA)
              ? (_openBlock(), _createBlock(_component_LinkButton, {
                  key: 0,
                  class: "search-button p-button-raised",
                  label: _ctx.vm.overviewButtonText,
                  url: _ctx.ctaUrl
                }, null, 8, ["label", "url"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 2))
}