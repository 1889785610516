<template>
	<a class="service-card-item" :href="item.productPageUrl" style="text-decoration: none">
		<Card
            :class="{ 'service-grid-card': isGridLayout, 'service-list-card': !isGridLayout, compact: compact, 'cross-sell': isCrossSell }"
			:style="{ backgroundColor: item.backgroundColor }"
			:title="formatProductName(item.name, item.brand)"
			@click="openUrl(item.productPageUrl)">
            <template #content>
                <div class="h-100 card-list-info">
                    <div class="d-flex mb-1">
                        <div class="align-self-center mr-2">
                            <img v-if="item.iconUrl" v-lazy="item.iconUrl" :alt="item.iconAlt" class="overview-product-icon" :title="item.name" />
                        </div>
                        <div class="d-flex flex-column">
                            <p class="mb-0 text-muted lead">{{ $t('enum.itemProductType.' + item.productType) }}</p>
                            <h4 class="mb-0 productname">{{ formatProductName(item.name, item.brand) }}</h4>
                            <div class="mt-2 mt-lg-0 d-flex">
                                <p v-if="item.basePrice" class="lead text-muted d-flex flex-wrap text-right justify-content-end">
                                    <span v-if="isServiceType">
                                        <b class="text-primary">{{ formatCurrency(item.basePrice) }}</b>
                                    </span>
                                    <span v-else>
                                        {{ $t("common.from") }}
                                        <b class="text-primary ml-1">{{ formatCurrency(item.basePrice) }}</b>
                                    </span>
                                </p>
                                <p v-if="!item.basePrice" class="lead text-muted d-flex flex-wrap text-right justify-content-end">
                                    <span>
                                        <b class="lead price-text text-muted">{{ $t("product.availableOnRequest")}}</b>
                                    </span>
                                </p>
                            </div>
                            <Tag v-if="isCrossSell" class="mb-auto" :value="$t('enum.itemProductType.' + item.productType)" />
                        </div>
                    </div>
                    <template v-if="!isCrossSell">
                        <Divider />
                        <p v-if="showIntro" class="intro-text text-preline" v-html="item.intro" />
                    </template>
                </div>
                <div v-if="!isGridLayout" class="h-100 card-list-image" :style="{ backgroundImage: `url(${ item.overviewImageUrl}), url(${(isServiceType() ? '/Static/images/default-dienst.png' : '/Static/images/default-training.png')})` }"></div>
            </template>
		</Card>
	</a>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Divider from "primevue/divider";
import Tag from "primevue/tag";
import { Component, Prop } from "vue-facing-decorator";
import { ItemProductType } from "@/types/enum/itemProductType";
import { PropType } from "vue";
import { ServiceItemViewModel } from "@/types/models/service/serviceItemViewModel";

@Component({ components: { Divider, Tag } })
export default class ServiceOverviewItemCard extends BaseComponent {
    @Prop({ type: Object as PropType<ServiceItemViewModel>, required: true, default: {} }) item!: ServiceItemViewModel;

	@Prop({ type: String, required: true, default: "grid" }) layout!: string;

	@Prop({ type: Boolean, required: false, default: false }) compact!: boolean;

    @Prop({ type: Boolean, default: false, required: false }) isCrossSell!: boolean;

    @Prop({ type: Boolean, required: false, default: true }) showIntro!: boolean;
    // todo?
    @Prop({ type: Boolean, required: false, default: true }) showHeaderImage!: boolean;

	created() {
        console.log('ServiceOverviewItemCard.vue');
    }

    isServiceType(): boolean {
        return this.item?.productType === ItemProductType.Service;
    }

	get isGridLayout(): boolean {
		return this.layout === "grid";
	}
}
</script>

<style scoped lang="scss">
    .service-card-item {
        display: block;
        height: 100%;
    }

    .productname {
	    display: -webkit-box;
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
	    font-size: 1.4rem;
	    line-height: 1.2;
	    overflow: hidden;
    }

    .course-names {
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .intro-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .overview-product-icon {
        height: 67px;
        width: 67px;
    }

    ::v-deep(.p-card) {
        border: var(--surface-border);
        transition: all 0.4s;
        height: 100%;

        &:hover {
            box-shadow: 4px 4px 2px 0px rgb(0 0 0 / 20%), 0 1px 4px 0 rgb(0 0 0 / 14%), 0 2px 8px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
        }

        .p-card-body {
            padding: 0;
            flex: 1;
        }

        .p-card-content {
            padding: 0;
            display: flex;
            height: 100%;
        }

        .card-list-info {
            padding: 40px 50px;
            background-color: #f9f9f9;
        }

        .card-list-image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #FEE4EB;
        }

        &.compact {
            height: 100%;

            .p-card-content {
                padding: 0;
            }
        }

        &.cross-sell {
            height: 100%;

            .p-card-body {
                height: 100%;
            }

            .p-card-footer {
                display: none;
            }

            .p-card-content {
                padding: 0;
                height: 100%;
                min-height: 150px;

                .overview-product-icon {
                    height: 54px;
                    width: 54px;
                    margin-right: 0.5rem;
                }

                .lead {
                    font-size: 1.1rem;
                }

                h4 {
                    font-size: 1.25rem;
                }
            }
        }

        &.service-list-card {
            display: flex;
            flex-wrap: wrap;
            margin: 0.75rem;

            .card-list-image, .card-list-info {
                flex: 1 1 50%;
                box-sizing: border-box;
            }
        }

        &.service-grid-card {
            position: relative;

            .p-card-body {
                height: 100%;
            }

            .p-card-header {
                img {
                    height: 225px;
                    object-fit: cover;
                }
            }
        }
    }
</style>
