<template>
	<!--grid-->
	<div v-if="isGridLayout" class="m-2 surface-a">
		<div class="p-3">
			<!--image-->
			<!--<Skeleton height="150px" width="100%" />-->
			<div class="d-flex mt-2">
				<!--icon-->
				<Skeleton class="mr-2" style="background-color: #FEE4EB" height="4.5rem" shape="square" width="4rem" />
				<div>
					<!--label-->
					<Skeleton class="mb-2" width="5rem" />
					<!--title-->
					<Skeleton class="mb-2" width="15rem" />
					<!--price-->
					<Skeleton class="mb-2" width="10rem" />
				</div>
			</div>
			<div class="d-flex flex-column mt-2">
				<!--content-->
				<Skeleton class="mb-1" height=".75rem" width="100%" />
				<Skeleton height=".75rem" width="80%" />
			</div>
		</div>
	</div>
	<!--list-->
	<div v-else class="d-flex flex-shrink-0 m-2 surface-a">
		<div class="d-flex flex-column p-3 w-50">
			<div class="d-flex mt-2">
				<!--icon-->
				<Skeleton class="mr-2" style="background-color: #FEE4EB" height="4.5rem" shape="square" width="4rem" />
				<div>
					<!--label-->
					<Skeleton class="mb-2" width="5rem" />
					<!--title-->
					<Skeleton class="mb-2" width="15rem" />
					<!--price-->
					<Skeleton class="mb-2" width="10rem" />
				</div>
			</div>
			<div class="d-flex flex-column mt-2">
				<!--content-->
				<Skeleton class="mb-1" height=".75rem" width="100%" />
				<Skeleton height=".75rem" width="80%" />
			</div>
		</div>
		<div class="w-50 d-flex flex-column">
			<Skeleton style="background-color: #FEE4EB" height="100%" width="100%" />
		</div>
	</div>
</template>

<script lang="ts">
	import BaseComponent from "@/components/base/baseComponent.vue";
	import { Component, Prop } from "vue-facing-decorator";
	import { PropType } from "vue";
	import { OverviewLayoutType } from "@/views/layout/productOverviewLayout.vue";
	import Skeleton from "primevue/skeleton";

	@Component({ components: { Skeleton } })
	export default class ServiceOverviewItemCardSkeleton extends BaseComponent {
		@Prop({ type: String as PropType<OverviewLayoutType>, required: true, default: "grid" })
		type!: OverviewLayoutType;

		get isGridLayout(): boolean {
			return this.type === "grid";
		}
	}
</script>

<style scoped lang="scss"></style>
