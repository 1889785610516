import { defineStore } from 'pinia';
import { ExpertProfileViewModel } from '@/types/models/expert/expertProfileViewModel';
import httpClient from '@/types/helpers/apiHelper';
import { AxiosResponse } from 'axios';

interface ExpertStoreState {
	isBookingRequestModalVisible: boolean;
	isCancelOrDeclineBookingModalVisible: boolean;
	isAcceptBookingModalVisible: boolean;
	isCompleteBookingModalVisible: boolean;
	isBookingReviewModalVisible: boolean;
	isBookingReviewDetailsModalVisible: boolean;
	isSendProfileModalVisible: boolean;
	isLevelModalVisible: boolean;
	expertProfile: ExpertProfileViewModel | null;
}

export type ExpertModalType =
	| 'BookingRequest'
	| 'CancelOrDeclineBooking'
	| 'AcceptBooking'
	| 'CompleteBooking'
	| 'ReviewBooking'
	| 'ReviewBookingDetails'
	| 'SendProfile'
	| 'ExpertLevel';

export const useExpertStore = defineStore('expert', {
	state: () =>
		({
			isBookingRequestModalVisible: false,
			isCancelOrDeclineBookingModalVisible: false,
			isAcceptBookingModalVisible: false,
			isCompleteBookingModalVisible: false,
			isBookingReviewModalVisible: false,
			isBookingReviewDetailsModalVisible: false,
			isLevelModalVisible: false,
			isSendProfileModalVisible: false,
			expertProfile: null,
		}) as ExpertStoreState,
	actions: {
		showModal(type: ExpertModalType) {
			switch (type) {
				case 'BookingRequest':
					this.isBookingRequestModalVisible = true;
					break;
				case 'CancelOrDeclineBooking':
					this.isCancelOrDeclineBookingModalVisible = true;
					break;
				case 'AcceptBooking':
					this.isAcceptBookingModalVisible = true;
					break;
				case 'CompleteBooking':
					this.isCompleteBookingModalVisible = true;
					break;
				case 'ReviewBooking':
					this.isBookingReviewModalVisible = true;
					break;
				case 'ReviewBookingDetails':
					this.isBookingReviewDetailsModalVisible = true;
					break;
				case 'SendProfile':
					this.isSendProfileModalVisible = true;
					break;
				case 'ExpertLevel':
					this.isLevelModalVisible = true;
					break;
			}
		},
		hideModal(type: ExpertModalType) {
			switch (type) {
				case 'BookingRequest':
					this.isBookingRequestModalVisible = false;
					break;
				case 'CancelOrDeclineBooking':
					this.isCancelOrDeclineBookingModalVisible = false;
					break;
				case 'AcceptBooking':
					this.isAcceptBookingModalVisible = false;
					break;
				case 'CompleteBooking':
					this.isCompleteBookingModalVisible = false;
					break;
				case 'ReviewBooking':
					this.isBookingReviewModalVisible = false;
					break;
				case 'ReviewBookingDetails':
					this.isBookingReviewDetailsModalVisible = false;
					break;
				case 'SendProfile':
					this.isSendProfileModalVisible = false;
					break;
				case 'ExpertLevel':
					this.isLevelModalVisible = false;
					break;
			}
		},
		fetchExpertProfile(
			cadacAccountId: string,
			editingLanguage: string
		): Promise<void | AxiosResponse<ExpertProfileViewModel>> {
			return httpClient
				.get<ExpertProfileViewModel>(`/api/expert/profile/${cadacAccountId}/${editingLanguage}`)
				.then((res) => {
					if (res.data) this.expertProfile = res.data;
				});
		},
	},
});
