<template>
	<!--v-if="vm.productType === productTypeEnum.Expert"-->
	<ExpertOverviewItemCard v-if="vm.productType === productTypeEnum.Expert" :is-cross-sell="isCrossSell" :item="expertProduct" layout="grid" />
	<!--v-else-if="vm.productType === productTypeEnum.Software"-->
	<ProductOverviewItemCard v-else-if="vm.productType === productTypeEnum.Software" :is-cross-sell="isCrossSell" :item="softwareProduct" layout="grid" />
	<!--v-else-if="vm.productType === productTypeEnum.Collection"-->
	<ProductOverviewItemCard v-else-if="vm.productType === productTypeEnum.Collection" :is-cross-sell="isCrossSell" :item="collectionProduct" layout="grid" />
	<!--services, support, training-->
	<ServiceOverviewItemCard v-else :compact="!isCrossSell" :is-cross-sell="isCrossSell" :item="vm" layout="grid" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ExpertOverviewItemCard from "@/components/experts/expertOverviewItemCard.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import ServiceOverviewItemCard from "@/components/service/serviceOverviewItemCard.vue";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { CollectionItemViewModel } from "@/types/models/software/collectionItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertItemViewModel } from "@/types/models/expert/expertItemViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { PropType } from "vue";
import { ServiceItemViewModel } from "../../types/models/service/serviceItemViewModel";

@Component({
	components: {
		ExpertOverviewItemCard,
		ProductOverviewItemCard,
        ServiceOverviewItemCard,
	},
})
export default class BaseProductOverviewItemCard extends BaseComponent {
	@Prop({
		type: Object as PropType<BaseProductItemViewModel>,
		required: true,
	})
	vm!: BaseProductItemViewModel;

	@Prop({ type: Boolean, default: false, required: false })
	isCrossSell!: boolean;

	get productTypeEnum(): typeof ItemProductType {
		return ItemProductType;
	}

	get expertProduct(): ExpertItemViewModel | undefined {
		return this.vm.productType === ItemProductType.Expert ? (this.vm as ExpertItemViewModel) : undefined;
	}

    get softwareProduct(): ServiceItemViewModel | undefined {
        return this.vm.productType === ItemProductType.Software ? (this.vm as ServiceItemViewModel) : undefined;
	}

	get collectionProduct(): CollectionItemViewModel | undefined {
		return this.vm.productType === ItemProductType.Collection
			? (this.vm as CollectionItemViewModel)
			: undefined;
	}
}
</script>

<style scoped lang="scss"></style>
