<template>
	<ProductOverviewLayout :vm="overviewVm" @on-filter-change="onFilterChange" @on-search="onSearch" @on-sort-change="onSortChange">
		
		<template #header>
			<div class="pt-3 pb-2">
				<slot name="breadcrumbs" />
				<h1 v-if="vm.pageTitle" class="mb-0">{{ vm.pageTitle }}</h1>
			</div>
		</template>

		<template #grid-item="{ data }">
			<div v-if="!isSpinnerVisible('product-overview')" class="col-lg-6 mb-2">
				<ServiceOverviewItemCard :compact="isSmallScreen" :item="data" layout="grid" />
			</div>
			<div v-else class="col-md-12 col-lg-6">
				<ServiceOverviewItemCardSkeleton type="list" />
			</div>
		</template>

		<template #list-item="{ data }">
			<ServiceOverviewItemCard v-if="!isSpinnerVisible('product-overview')" :item="data" layout="list" />
			<ServiceOverviewItemCardSkeleton v-else type="list" />
		</template>

	</ProductOverviewLayout>
</template>

<script lang="ts">
    import BaseComponent from "@/components/base/baseComponent.vue";
    import { Component, Prop } from "vue-facing-decorator";
    import { IServiceOverview } from "@/types/viewModels/myCadac/serviceOverviewViewModel";
    import { PropType } from "vue";
    import ServiceOverviewItemCardSkeleton from "@/components/service/serviceOverviewItemCardSkeleton.vue";
    import ServiceOverviewItemCard from "@/components/service/serviceOverviewItemCard.vue";
    import ProductOverviewLayout, { IProductOverviewLayout } from "@/views/layout/productOverviewLayout.vue";
    import { TrainingItemViewModel } from "../../types/models/training/trainingItemViewModel";
    import { Log } from "../../types/helpers/logHelper";
    import { OverviewSortType } from "../../types/enum/overviewSortType";
    import { OverviewFilterViewModel } from "../../types/models/common/filters/overviewFilterViewModel";
    import { ServiceItemViewModel } from "../../types/models/service/serviceItemViewModel";
    import { useProductOverviewFilterStore } from "../../store/common/productOverviewFiltersStore";

    @Component({
        components: {
			ProductOverviewLayout,
			ServiceOverviewItemCard,
            ServiceOverviewItemCardSkeleton
        },
    })
    export default class ServiceOverview extends BaseComponent {
        @Prop({ type: Object as PropType<IServiceOverview>, required: true, default: {} }) vm!: IServiceOverview;

        overviewItems: TrainingItemViewModel[] | ServiceItemViewModel[] = [];
        filterStore = useProductOverviewFilterStore();

	    showArchived: { [title: string]: boolean } = {};
	    isActivating: { [number: string]: boolean } = {};
	    hasActivated: { [number: string]: boolean } = {};

	    isLoading = false;
	    hasError = false;

        created(): void {
            Log.info("ServiceOverview.vue");
            this.overviewItems = this.vm.overviewItems;
            this.vm.filters?.length && this.filterStore.init(this.vm.filters);
        }

        get overviewVm(): IProductOverviewLayout {
            const productType = this.vm.overviewItems[this.vm.overviewItems.length - 1]?.productType;
            return { productType, items: this.vm.overviewItems, defaultViewLayout: "list" };
        }

        onSortChange(sortType: OverviewSortType): void {
            this.filterStore.sortType = sortType;
            this.updateFilters();
        }

        onFilterChange(_filter: OverviewFilterViewModel): void {
            this.updateFilters();
        }

        onSearch(query: string): void {
            if (!this.filterStore.hasQueryFilter) return;
            this.filterStore.updateSearchQuery(query);
            this.updateFilters();
        }

        private updateFilters(): void {
            this.loadingStore.showSpinner("product-overview");
            this.loadingStore.increaseLoadingCount();
            this.filterStore
                .updateTrainingFilters()
                .then((data) => {
                    this.overviewItems = data?.overviewItems ? [...data.overviewItems] : [];
                })
                .catch((err) => Log.error(err))
                .finally(() => {
                    this.loadingStore.hideSpinner("product-overview");
                    this.loadingStore.decreaseLoadingCount();
                });
        }
    }
</script>

<style lang="scss" scoped>
    :deep(.product-grid-card) {
        &.p-card {
            min-height: 200px;
        }
    }

    :deep(.p-dataview) {
        .p-dataview-header {
            top: var(--navbarHeightInPx);
        }
    }
</style>