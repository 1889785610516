import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bd9a792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-3 p-3" }
const _hoisted_2 = { class: "d-flex flex-column flex-lg-row mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "product-header-icon align-self-lg-center"
}
const _hoisted_4 = ["alt", "title"]
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "mb-0 text-muted lead" }
const _hoisted_7 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_8 = ["href", "title"]
const _hoisted_9 = {
  key: 1,
  class: "mb-0"
}
const _hoisted_10 = { class: "mr-1" }
const _hoisted_11 = { class: "mr-1" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "row pt-2"
}
const _hoisted_15 = { class: "text-primary" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "lead text-bold" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "text-bold mb-0" }
const _hoisted_20 = {
  key: 0,
  class: "header-image"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "container-fluid product-header-bg",
      style: _normalizeStyle({ background: _ctx.vm.backgroundColor })
    }, [
      _renderSlot(_ctx.$slots, "breadcrumbs", {}, undefined, true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.vm.iconUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _withDirectives(_createElementVNode("img", {
                  alt: _ctx.vm.iconAlt || _ctx.vm.name,
                  title: _ctx.vm.name
                }, null, 8, _hoisted_4), [
                  [_directive_lazy, _ctx.vm.iconUrl]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.showOverviewImage() ? _ctx.$t('enum.itemProductType.' + _ctx.vm.productType) : _ctx.vm.brand), 1),
            ((_ctx.userProfile?.isCadacSales || _ctx.userProfile?.isImpersonated) && _ctx.vm.crmUrl)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_7, [
                  _createElementVNode("a", {
                    href: _ctx.vm.crmUrl,
                    target: "_blank",
                    title: _ctx.$t('common.goToCrm')
                  }, _toDisplayString(_ctx.formatProductName(_ctx.vm.name, _ctx.vm.brand)), 9, _hoisted_8)
                ]))
              : (_openBlock(), _createElementBlock("h1", _hoisted_9, _toDisplayString(_ctx.formatProductName(_ctx.vm.name, _ctx.vm.brand)), 1))
          ])
        ]),
        (_ctx.vm.price)
          ? (_openBlock(), _createBlock(_component_Chip, {
              key: 0,
              class: "p-chip-primary"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("common.from")), 1),
                _createVNode(_component_i18n_t, { keypath: "common.pricePerMonth" }, {
                  default: _withCtx(() => [
                    _createElementVNode("b", _hoisted_11, _toDisplayString(_ctx.formatCurrency(_ctx.vm.price)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: "lead text-preline mt-1 product-intro",
          innerHTML: _ctx.vm.intro
        }, null, 8, _hoisted_12),
        (_ctx.showTeachingsMaterialButton && _ctx.vm.description)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              icon: "pi pi-info-circle",
              label: _ctx.$t('product.lblShowTeachingsMaterialButton'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true)),
              id: "productInfoBtn"
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Dialog, {
          visible: _ctx.visible,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
          modal: "",
          header: `${_ctx.$t('training.dialog.lblHeaderTitle')} ${_ctx.formatProductName(_ctx.vm.name, _ctx.vm.brand)}`,
          style: { width: '50rem' },
          breakpoints: { '1199px': '75vw', '575px': '90vw' }
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", {
              class: "lead rte mt-1 product-intro",
              innerHTML: _ctx.vm.description
            }, null, 8, _hoisted_13)
          ]),
          _: 1
        }, 8, ["visible", "header"])
      ]),
      (_ctx.vm.usps?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.usps, (usp) => {
              return (_openBlock(), _createElementBlock("div", {
                key: usp,
                class: "col-auto d-flex flex-wrap align-items-center mb-2"
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "text-success mr-2",
                  icon: ['far', 'check-circle'],
                  size: "2x"
                }),
                _createElementVNode("b", _hoisted_15, _toDisplayString(usp), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.vm.relatedProducts?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("product.lblProductsInCollection")), 1),
            _createVNode(_component_Carousel, {
              "num-scroll": 2,
              "num-visible": 3,
              value: _ctx.vm.relatedProducts,
              responsiveOptions: _ctx.responsiveOptions
            }, {
              item: _withCtx((slotProps) => [
                _createElementVNode("a", {
                  class: "d-flex flex-column align-items-center",
                  href: slotProps.data.productPageUrl,
                  style: {"text-decoration":"none"}
                }, [
                  (slotProps.data.iconUrl)
                    ? (_openBlock(), _createBlock(_component_Avatar, {
                        key: 0,
                        alt: slotProps.data.iconAlt || slotProps.data.name,
                        image: slotProps.data.iconUrl,
                        size: "large",
                        title: slotProps.data.name
                      }, null, 8, ["alt", "image", "title"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.formatProductName(slotProps.data.name, slotProps.data.brand)), 1)
                ], 8, _hoisted_18)
              ]),
              _: 1
            }, 8, ["value", "responsiveOptions"])
          ]))
        : _createCommentVNode("", true)
    ], 4),
    (_ctx.showOverviewImage())
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _withDirectives(_createElementVNode("img", {
            onError: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setDefaultImage && _ctx.setDefaultImage(...args))),
            class: "w-100"
          }, null, 544), [
            [_directive_lazy, _ctx.vm.overviewImageUrl || (_ctx.isServiceType() ? '/Static/images/default-dienst.png' : '/Static/images/default-training.png')]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}