<template>
	<div class="container-fluid product-overview-bg">
		<div class="container">
			<slot name="header" />
			<hr />
			<div class="d-flex">
				<SlideSidebar v-show="filterStore.hasOptionFilters && !isSmallScreen" v-model="showFilters" @close="() => (showFilters = $event)">
					<template #header>
						<h4 class="mb-0">Filters</h4>
					</template>
					<template #content>
						<ScrollPanel style="width: 100%; height: 70vh; overflow-x: hidden">
							<ProductFilters @filters-changed="onFilterChange">
								<ExpertFilters v-if="isExpertProduct" @on-expert-filter-change="onFilterChange" />
							</ProductFilters>
						</ScrollPanel>
					</template>
				</SlideSidebar>
				<Sidebar v-if="filterStore.hasOptionFilters && isSmallScreen" v-model:visible="showFilters" class="surface-200">
					<div>
						<h4>Filters</h4>
						<ProductFilters @filters-changed="onFilterChange">
							<ExpertFilters v-if="isExpertProduct" @on-expert-filter-change="onFilterChange" />
						</ProductFilters>
					</div>
				</Sidebar>

				<DataView class="w-100 data-view-list" :layout="isSmallScreen ? 'grid' : viewLayout" :value="overviewItems">
					<template #header>
						<div class="header-left justify-content-between justify-content-lg-start flex-grow-1 flex-lg-grow-0">
							<Button v-if="filterStore.hasOptionFilters"
									:badge="filterStore.hasActiveFilters ? filterStore.activeFilterCount.toString() : ''"
									class="p-button-outlined mr-1 flex-shrink-0"
									:label="$t('common.filters')"
									@click="showFilters = !showFilters" />
							<Dropdown v-if="filterStore.sortOptions.length > 0"
									  v-model="filterStore.sortKey"
									  append-to="self"
									  option-label="label"
									  :options="filterStore.sortOptions"
									  :placeholder="$t('product.sort_placeholder')"
									  scroll-height="400px"
									  @change="onSortChange($event)" />
						</div>

						<div class="header-right flex-grow-1 flex-lg-grow-0">
							<span v-if="filterStore.hasQueryFilter" class="p-input-icon-left p-input-icon-right header-search">
								<i class="pi pi-search" :class="{ 'text-primary': filterStore.hasActiveQueryFilter }" />
								<InputText
									v-model="queryString"
									v-debounce:1s="onSearch"
									class="mr-1"
									:placeholder="searchPlaceholderText"
									type="search"
								/>
								<i
									v-if="queryString?.length"
									class="pi pi-times icon-clear"
									@click="queryString = ''; onSearch(queryString);"
								/>
							</span>
							<DataViewLayoutOptions
								v-if="!isSmallScreen"
								v-model="viewLayout"
								class="ml-1"
								@click="onLayoutChanged"
							/>
						</div>
					</template>
					<template #empty>
						<Message :closable="false" severity="warn">{{ $t("product.message.noOverviewItems") }}</Message>
					</template>
					<template #list="item">
						<slot :data="item.data" name="list-item" />
					</template>
					<template #grid="item">
						<slot :data="item.data" name="grid-item" />
					</template>
				</DataView>
			</div>
			<ScrollTop target="parent" :threshold="200" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import ExpertFilters from "@/components/experts/expertFilters.vue";
import ProductFilters from "@/components/product/productFilters.vue";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Sidebar from "primevue/sidebar";
import SlideSidebar from "@/components/common/slideSidebar.vue";
import { Component, Emit, Prop, Watch } from "vue-facing-decorator";
import { ItemProductType } from "@/types/enum/itemProductType";
import { OverviewFilterViewModel } from "@/types/models/common/filters/overviewFilterViewModel";
import { OverviewSortType } from "@/types/enum/overviewSortType";
import { PropType } from "vue";
import { useProductOverviewFilterStore } from "@/store/common/productOverviewFiltersStore";

export interface IProductOverviewLayout {
	items: unknown[];
	productType: ItemProductType;
	defaultViewLayout?: OverviewLayoutType;
}

export type OverviewLayoutType = "grid" | "list";

@Component({
	components: {
		DataView,
		DataViewLayoutOptions,
		ExpertFilters,
		ProductFilters,
		ScrollPanel,
		ScrollTop,
		Sidebar,
		SlideSidebar,
	},
})
export default class ProductOverviewLayout extends BaseComponent {
	@Prop({ type: Object as PropType<IProductOverviewLayout>, required: true, default: {} })
	vm!: IProductOverviewLayout;

	overviewItems!: unknown[];
	viewLayout!: OverviewLayoutType;

	queryString = "";
	showFilters = false;

	filterStore = useProductOverviewFilterStore();

	@Watch("vm.items", { deep: true }) onItemsChanged(): void {
		this.overviewItems = this.vm.items;
	}

	onLayoutChanged(): void {
		sessionStorage.setItem(this.overviewLayoutStorageKey, this.viewLayout);

		const element = document.querySelector(`.data-view-list`);
		if (!element) return;

		const headerOffset = 80;
		const elementPosition = element.getBoundingClientRect().top;
		const offsetPosition = elementPosition + window.scrollY - headerOffset;

		setTimeout(() => window.scrollTo({ top: offsetPosition, behavior: "smooth" }));
	}

	@Emit()
	onSortChange(event: { value: { value: OverviewSortType } }): OverviewSortType {
		return event.value.value;
	}

	@Emit()
	onFilterChange(filter: OverviewFilterViewModel): OverviewFilterViewModel {
		return filter;
	}

	@Emit()
	onSearch(query: string): string {
		this.trackSearch(query);
		if (!this.filterStore.hasQueryFilter) return "";
		return query;
	}

	created(): void {
		this.overviewItems = this.vm.items || [];
		this.viewLayout =
			(sessionStorage.getItem(this.overviewLayoutStorageKey) as OverviewLayoutType) ||
			this.vm.defaultViewLayout ||
			"grid";

		if (this.filterStore.hasQueryFilter) {
			this.queryString = this.filterStore.queryFilter.props?.queryString || "";
		}

		this.showFilters = this.isExpertProduct;		
	}

	get overviewLayoutStorageKey(): string {
		return `CDC_OverviewLayout_${this.vm.productType}`;
	}

	get isExpertProduct(): boolean {
		return this.vm.productType === ItemProductType.Expert;
	}

	get searchPlaceholderText(): string {
		switch (this.vm.productType) {
			case ItemProductType.Software:
			case ItemProductType.Collection:
				return this.$t("product.searchSoftware_placeholder");
			case ItemProductType.Training:
				return this.$t("training.search_placeholder");
			case ItemProductType.Expert:
				return this.$t("expert.searchPlaceholder");
			default:
				return this.$t("common.search_placeholder");
		}
	}
}
</script>

<style scoped lang="scss">
.product-overview-bg {
	background: var(--surface-b);
	position: relative;
	padding-bottom: 2rem;

	.container {
		min-height: 55vh;
	}
}

::v-deep(.p-dataview-grid) {
	.grid {
		display: flex;
		flex-wrap: wrap;
	}
}

::v-deep(.p-dataview) {
	.p-dataview-header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0.5rem 1.5rem;
		background: var(--surface-200);
		box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.05);
		position: sticky;
		top: 0;
		z-index: 1;

		.header-left {
			display: flex;
			margin: 0.5rem 0.25rem;
		}

		.header-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin: 0.5rem 0.25rem;
		}

		.header-search {
			flex-grow: 1;
		}
	}

	.p-button.p-button-icon-only {
		padding: 0.75rem 0;
	}

	.p-button-outlined {
		background: var(--surface-a);

		.p-badge {
			color: #fff;
			background: var(--primary-color);
		}
	}

	.p-dataview-content {
		background: var(--surface-200);
		padding: 1.5rem 0.75rem;
	}
}

::v-deep(.p-scrollpanel) {
	&:not(:hover) {
		.p-scrollpanel-bar {
			opacity: 0.25;
		}
	}

	.p-scrollpanel-bar {
		background: var(--primary-color);

		.p-scrollbar-y {
			width: 0.2rem;
		}
	}
}
</style>
